import React from 'react';
import './css/App.css';

/**
 * We're using MUI Joy UI. We're also using Material UI.
 * Material UI is used for components that are not yet available in Joy UI.
 * 
 * @see https://mui.com/joy-ui/guides/using-joy-ui-and-material-ui-together/
 */
import {
  experimental_extendTheme as materialExtendTheme,
  Experimental_CssVarsProvider as MaterialCssVarsProvider,
  THEME_ID as MATERIAL_THEME_ID,
} from '@mui/material/styles';
import { CssVarsProvider as JoyCssVarsProvider, extendTheme } from '@mui/joy/styles';
import CssBaseline from '@mui/joy/CssBaseline';

// body
import '@fontsource/public-sans';

// pointpost brand
import './fonts/MuseoModerno.css'; 

// timer
import '@fontsource/chivo-mono/300.css';
import '@fontsource/chivo-mono/800.css';

/**
 * see https://m2.material.io/design/color/the-color-system.html#tools-for-picking-colors
 */
const pointpostTheme = {
  cssVarPrefix: 'pointpost',
  colorSchemes: {
    light: {
      palette: {
        background: {
          default: '#ffffff', // light mode background
        },
        text: {
          primary: '#333333',
          secondary: '#999999',
          tertiary: '#cccccc',
        },
        primary: {
          50: '#eaeee8',
          100: '#cbd5c5',
          200: '#a8b99e',
          300: '#859c77',
          400: '#6a8759',
          500: '#50723c',
          600: '#496a36',
          700: '#405f2e',
          800: '#375527',
          900: '#27421a',
          A100: '#a9ff83',
          A200: '#85ff50',
          A400: '#62ff1d',
          A700: '#50ff03',
          main: '#50723C',
        },
        secondary: { 
          50: '#eee8eb',
          100: '#d5c5ce',
          200: '#b99ead',
          300: '#9c778c',
          400: '#875974',
          500: '#723c5b',
          600: '#6a3653',
          700: '#5f2e49',
          800: '#552740',
          900: '#421a2f',
          A100: '#ff83c1',
          A200: '#ff50a7',
          A400: '#ff1d8e',
          A700: '#ff0381',
          main: '#723c5b',
        },
      },
    },
    dark: {
      palette: {
        background: {
          default: '#000000', // dark mode background
        },
        text: {
          primary: '#eeeeee',
          secondary: '#cccccc',
          tertiary: '#999999',
        },
        primary: {
          50: '#eaeee8',
          100: '#cbd5c5',
          200: '#a8b99e',
          300: '#859c77',
          400: '#6a8759',
          500: '#50723c',
          600: '#496a36',
          700: '#405f2e',
          800: '#375527',
          900: '#27421a',
          A100: '#a9ff83',
          A200: '#85ff50',
          A400: '#62ff1d',
          A700: '#50ff03',
          main: '#50723C',
        },
        secondary: { 
          50: '#eee8eb',
          100: '#d5c5ce',
          200: '#b99ead',
          300: '#9c778c',
          400: '#875974',
          500: '#723c5b',
          600: '#6a3653',
          700: '#5f2e49',
          800: '#552740',
          900: '#421a2f',
          A100: '#ff83c1',
          A200: '#ff50a7',
          A400: '#ff1d8e',
          A700: '#ff0381',
          main: '#723c5b',
        },
      },
    },
  },
  typography: {
    h1: {
      fontFamily: "'Museo Moderno', sans-serif",
      fontSize: "2rem",
      fontVariantNumeric: "oldstyle-nums",
    },
    monospace: {
      fontFamily: "'Chivo Mono', monospace, sans-serif",
    },
  },
  components: {
    MuiFab: {
      styleOverrides: {
        root: {
          position: 'fixed',
          bottom: '2rem',
          right: '2rem'
        }
      }
    },
    JoyAvatar: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          ...(ownerState.size === 'xs' && {
            width: `var(--Avatar-size, 1.5rem)`,
            height: `var(--Avatar-size, 1.5rem)`,
          }),
          ...(ownerState.size === 'xl' && {
            width: `var(--Avatar-size, 3.5rem)`,
            height: `var(--Avatar-size, 3.5rem)`,
          }),
        }),
      },
    },
  },
};

const theme = extendTheme(pointpostTheme);
const materialTheme = materialExtendTheme(pointpostTheme);

const ThemeProvider = ({ children }) => {
  return (
    <MaterialCssVarsProvider theme={{ [MATERIAL_THEME_ID]: materialTheme }} defaultMode="dark">
      <JoyCssVarsProvider theme={theme} defaultMode="dark">
        <CssBaseline />
        {children}
      </JoyCssVarsProvider>
    </MaterialCssVarsProvider>
  );
}

export default ThemeProvider;