import React, { useState, useEffect } from 'react';
import { db } from "../firebaseConfig";
import { Autocomplete, AutocompleteOption, Button, ListItemContent, ListItemDecorator, Stack } from '@mui/joy';
import { collection, getDocs } from 'firebase/firestore';
import { TeamAvatar } from '../common/TeamAvatar';

const ClubSearch = ({
    name = null,
    onChange = null,
    labelField = 'name',
    value = null,
    excludeIds = null,
    isButton = false,
    buttonLabel = null,
    handleButton = null,
    sx = null,
  }) => {
  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    const fetchClubs = async () => {
      const searchText = inputValue.toLowerCase().trim();
      if (searchText.length >= 2) {
        const querySnapshot = await getDocs(collection(db, 'clubs'));
        const fetchedClubs = querySnapshot.docs.filter(doc => 
          (
            doc.data().name?.toLowerCase().includes(searchText) ||
            doc.data().clubName?.toLowerCase().includes(searchText)
          )
          && (!excludeIds || !excludeIds.includes(doc.id))
        ).map(doc => ({ id: doc.id, ...doc.data() }));
        setOptions(fetchedClubs);
      } else {
        setOptions([]);
      }
    };

    fetchClubs().catch(console.error);
  }, [inputValue, labelField, excludeIds]);

  return (
    <Stack direction="row" spacing="2" sx={{ ...sx }}>
      <Autocomplete
        freeSolo
        blurOnSelect
        disableClearable
        placeholder="Find a club..."
        onChange={(event, newValue) => {
          setSelected(newValue);
          if (onChange) {
            onChange( 
              { target: { name: name, value: newValue[labelField] }},
              newValue,
            );
          }
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
          setSelected(null);
          if (onChange) {
            onChange({ 
              target: { name: name, value: newInputValue },
            });
          }
        }}
        options={options}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        slotProps={{
          listbox: {
            disablePortal: options.length === 0 || inputValue.length < 2,
          },
        }}
        sx={{ flexGrow: 1 }}
        getOptionLabel={(option) => option[labelField]}
        renderOption={(props, option) => (
          <AutocompleteOption {...props}>
            <ListItemDecorator>
              <TeamAvatar src={`${option.imageUrl}.png`} />
            </ListItemDecorator>
            <ListItemContent sx={{ fontSize: 'sm', pl: '0.5rem' }}>
              {option[labelField]}
            </ListItemContent>
          </AutocompleteOption>
        )}
      />
      { isButton && <Button disabled={!selected} onClick={() => handleButton(selected)}>{ buttonLabel }</Button>}
    </Stack>
  );
};

export default ClubSearch;