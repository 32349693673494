import React, { useEffect, useState } from "react";
import { collection, getDocs, query } from "firebase/firestore";
import { db } from '../firebaseConfig';

import { Grid, Box, Sheet } from "@mui/joy";
import { Scoreboard } from "../scoreboard/Scoreboard";
import { FixtureProvider } from "../scoreboard/FixtureContext";
import Link from "../components/common/Link";

const Scoreboards = ({ clubId }) => {
  const [scoreboards, setScoreboards] = useState([]);

  useEffect(() => {
    const fetchScoreboards = async () => {
      const scoreboardsQuery = query(
        collection(db, `clubs/${clubId}/scoreboards`)
      );
      const scoreboardSnapshot = await getDocs(scoreboardsQuery);
      const scoreboardList = scoreboardSnapshot.docs.map(doc => ({
        ...doc.data(),
        id: doc.id,
        clubId: clubId,
      }));
      console.log({scoreboardList});
      setScoreboards(scoreboardList);
    };

    fetchScoreboards();
  }, [clubId]);

  const scoreboardStyle = {
    height: '200px',
    width: 'calc(200px * 1.33)',
    minWidth: 'calc(200px * 1.33)',
    overflow: 'hidden',
  }

  return (
    <Grid 
      container 
      columns={{ xs: 2, md: 4 }}
      spacing={{ xs: 2 }}
      sx={{ flexGrow: 1 }}
    >
      {scoreboards.map((scoreboard, index) => (
        <Grid xs={2} key={index}>
          {scoreboard.name && <Link to={`/scoreboard/${scoreboard.id}`}><strong>{scoreboard.name}</strong></Link>}
          <Box display="flex" justifyContent="center" alignItems="center" sx={{ mt: '0.2rem' }}>
            <Sheet variant="outlined" sx={scoreboardStyle}>
              <FixtureProvider>
                <Scoreboard isEmbedded props={scoreboard} />
              </FixtureProvider>
            </Sheet>
          </Box>
        </Grid>         
      ))}
    </Grid>
  );
}

export default Scoreboards;