import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import { getLocalStorage, setLocalStorage } from '../helpers/LocalStorage';

const loadClub = async ({ params }) => {
  const cachedClub = getLocalStorage('club');

  if (cachedClub?.id === params.clubId) {
    return cachedClub;
  } else {
    try {
      const docRef = doc(db, `clubs/${params.clubId}`);
      const docSnap = await getDoc(docRef);
      if (!docSnap.exists()) {
        throw new Response('Club not found', { status: 404, statusText: 'Club not found' });
      }
      const club = { ...docSnap.data(), id: docSnap.id };
      setLocalStorage('club', club);
      return club;
    } catch (error) {
      if (error instanceof Response) {
        throw error; // re-throw custom error response
      } else {
        throw new Response('Error loading club data', { status: 500, statusText: error.message });
      }
    }
  }
};

export default loadClub;