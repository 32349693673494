import React, { useEffect, useState } from "react";
import { collection, doc, getDocs, setDoc } from "firebase/firestore";
import { db } from '../firebaseConfig';

import { Grid, Switch, Typography } from "@mui/joy";
import { dateTimeString, formatDateRelative } from "../helpers/DateTime";

const Members = ({ clubId }) => {
  const [members, setMembers] = useState([]);
  const [switchStates, setSwitchStates] = useState([]);

  useEffect(() => {
    const fetchMembers = async () => {
      const snapshot = await getDocs(collection(db, `clubs/${clubId}/members`));
      const details = [];
      const states = [];

      snapshot.forEach(doc => {
        details.push({
          id: doc.id,
          ...doc.data(),
          createdAt: dateTimeString(doc.data().createdAt),
        });
        states.push(doc.data().isApproved || false);
      });

      console.log({details, states});

      setMembers(details);
      setSwitchStates(states);
    };

    fetchMembers();
  }, [clubId]);

  const handleApproveSwitch = (member, index, isApproved) => {
    console.log('Approve toggle for member:', member);
    setDoc(doc(db, `clubs/${clubId}/members/${member.id}`), { isApproved }, { merge: true });
    setSwitchStates(prevStates =>
      prevStates.map((state, i) => i === index ? !state : state)
    );
  };

  return (
    <Grid 
      container 
      columns={{ xs: 2, md: 4 }}
      spacing={{ xs: 2 }}
      sx={{ flexGrow: 1 }}
    >
      {members.map((member, index) => (
        <Grid xs={2} key={index}>
          <Switch
            variant="solid"
            name="isApproved"
            label="Approve"
            checked={switchStates[index]}
            onChange={(e) => handleApproveSwitch(member, index, e.target.checked)}
            sx={ { mr: '0.5rem' } }
          />
          <strong>{member.name}</strong>
          <Typography fontSize="xs">
            {' joined '}
            {formatDateRelative(member.createdAt, {isTodayExplicit: true, isMidSentence: true})}
          </Typography>
        </Grid>         
      ))}
    </Grid>
  );
}

export default Members;