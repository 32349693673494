import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button, Grid } from '@mui/joy';
import { Timing } from '../common/Timing';
import { TeamEmblem } from '../common/TeamEmblem';
import { ScoreControls } from './ScoreControls';
import { TeamName } from './TeamName';
import { getSecondsRemaining } from '../helpers/GetSecondsRemaining';
import { ScoreboardButton } from './ScoreboardButton';
import { Forum } from '@mui/icons-material';

export const Scorecard = ({ match, eventModal }) => {
  const [secondsOn, setSecondsOn] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    let timeout;
    
    const updateTimer = () => {
      const secondsRemaining = getSecondsRemaining(match);
      if (secondsRemaining !== null) {
        setSecondsOn(secondsRemaining);
      }
      if (match.timerIsRunning && secondsRemaining > 0) {
        // update the timer every 100ms
        timeout = setTimeout(updateTimer, 100);
      }
    }

    updateTimer();

    return () => {
      clearTimeout(timeout);
    };
  }, [match]);

  const broadcastUri = match?.broadcastRef?.id ? `/broadcast/${match.broadcastRef.id}` : null;
  const handleBroadcastClick = () => {
    navigate(broadcastUri);
  }

  return (
    <>
      <Grid container spacing={2} sx={{ flexGrow: 1, mb: '1rem' }} alignItems="flex-start">
        <Grid xs={10} sm={6} md={8}>
          <Timing secondsOn={secondsOn} match={match} eventModal={eventModal} />
        </Grid>
        <Grid xs={2} sm={6} md={4} display="flex" justifyContent="flex-end" sx={{ mt: '0rem' }}>
          { broadcastUri && (
            <Button variant="plain" onClick={handleBroadcastClick} sx={{ p: "0 0.5rem" }}>
              <Forum fontSize="md" />
            </Button>
          )}
          <ScoreboardButton match={match} />
        </Grid>
        <Grid xs={3} display="flex" justifyContent="center">
          <TeamEmblem src={match?.homeClub?.imageUrl} team={match?.homeTeam} style={{ maxHeight: '5rem' }} />
        </Grid>
        <Grid xs={9} alignContent="center">
          <TeamName name={match.homeTeam} />
          <ScoreControls team="home" goals={match.homeGoals} behinds={match.homeBehinds} eventModal={eventModal} />
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ flexGrow: 1, mb: '1rem' }} alignItems="flex-start">
        <Grid xs={3} display="flex" justifyContent="center">
          <TeamEmblem src={match?.awayClub?.imageUrl} team={match?.awayTeam} style={{ maxHeight: '5rem' }} />
        </Grid>
        <Grid xs={9}>
          <TeamName name={match.awayTeam} />
          <ScoreControls team="away" goals={match.awayGoals} behinds={match.awayBehinds} eventModal={eventModal} />
        </Grid>
      </Grid>
    </>
  );
};