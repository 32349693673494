// First, let's import the necessary dependencies
import { useState } from 'react';
import { useAuth } from '../hooks/useAuth';
import { db } from '../firebaseConfig';
import { collection, serverTimestamp, runTransaction, doc } from 'firebase/firestore';
import { useNavigate, Link as RouterLink, useLoaderData } from 'react-router-dom';
import Stack from '@mui/joy/Stack';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import Button from '@mui/joy/Button';
import { Accordion, AccordionDetails, AccordionGroup, AccordionSummary, Autocomplete, Option, Select, Switch, Typography } from '@mui/joy';
import Sheet from '../common/Sheet';
import ClubSearch from '../clubs/ClubSearch';
import { TeamAvatar } from '../common/TeamAvatar';
import { formatDateRelative } from '../helpers/DateTime';
import { formatGrade } from '../helpers/Grade';
import Title from '../common/Title';
import { ClubChip } from '../components/club/ClubChip';

const FixtureForm = () => {
  const { user } = useAuth();
  const d = new Date();
  const dateTimeLocalValue = (new Date(d.getTime() - d.getTimezoneOffset() * 60000).toISOString()).slice(0, 16);
  const navigate = useNavigate();

  const fixtureDataDefault = {
    gender: '',
    grade: '',
    round: '',
    homeTeam: '',
    homeClub: null,
    homeClubTeam: null,
    awayTeam: '',
    awayClub: null,
    awayClubTeam: null,
    venue: '',
    scheduledAt: dateTimeLocalValue,
    quarterLength: 20,
    createdAt: serverTimestamp(),
    createdBy: user.uid,
    updatedAt: serverTimestamp(),
    updatedBy: user.uid,
  };
  
  const [fixtureData, setFixtureData] = useState(fixtureDataDefault);
  const [, setFixtureId] = useState();
  const [homeTeamOptions, setHomeTeamOptions] = useState(null);
  const [awayTeamOptions, setAwayTeamOptions] = useState(null);
  const [isAwayTeamSame, setIsAwayTeamSame] = useState(true);
  const [gradeOptions, setGradeOptions] = useState([]);
  const club = useLoaderData();
  const clubId = club.id;
  const [isClubHome, setIsClubHome] = useState(false);
  const [isClubAway, setIsClubAway] = useState(false);

  const genderOptions = [
    '',
    'Mens',
    'Womens',
    'Boys',
    'Girls',
    'Mixed',
  ]

  // flattens the teams object to an array of team names
  const formatTeamOptions = (teams) => {
    if (!teams) return;
    return Object.keys(teams).map(team => {
      let label = "";
      if (teams[team]?.gender) label += teams[team].gender + " ";
      if (teams[team]?.grade) label += formatGrade(teams[team].grade);
      return label;
    });
  }

  const handleClubClick = (side) => {
    if (side === 'home') {
      handleClubChange(
        { target: { name: 'home', value: !isClubHome ? club.name : null } },
        !isClubHome ? club : null
      );
      setIsClubHome(!isClubHome);
    } else {
      handleClubChange(
        { target: { name: 'away', value: !isClubAway ? club.name : null } },
        !isClubAway ? club : null
      );
      setIsClubAway(!isClubAway);
    }
  }

  const handleClubChange = (e, club = null) => {
    const isHome = e.target.name === 'home';

    // if updating the home club and venue is empty, use the home club's venue
    const venue = (
      isHome &&
      fixtureData.venue === '' &&
      club && club.venue
    ) ? club.venue : fixtureData.venue;

    const teams = formatTeamOptions(club?.teams);

    if (isHome) {
      setHomeTeamOptions(teams || null);
    } else {
      setAwayTeamOptions(teams || null);
    }
    
    // include club's grades
    if (club?.teams) {
      // extract grades from each team
      const grades = Object.keys(club.teams).map(team => club.teams[team].grade);
      setGradeOptions(Array.from(new Set([...gradeOptions, ...grades])).sort());
    }

    setFixtureData({
      ...fixtureData,
      [e.target.name + 'Team']: e.target.value,
      [e.target.name + 'Club']: club || null,
      [e.target.name + 'ClubTeam']: '',
      [e.target.name + 'ClubTeamIndex']: '',
      venue: venue,
    });
  };

  const handleClubTeamChange = (e, teamIndex, side) => {
    const teamOptions = (side === 'home') ? homeTeamOptions : awayTeamOptions;

    const team = fixtureData?.[side + 'Club']?.teams?.[teamIndex];

    setFixtureData({
      ...fixtureData,
      [side + 'ClubTeam']: teamOptions?.[teamIndex] || '',
      [side + 'ClubTeamIndex']: teamIndex || '',
      quarterLength: team?.quarterLength || fixtureData.quarterLength,
      gender: team?.gender || '',
      grade: team?.grade || '',
    });
  }

  const handleAwayTeamSwitch = (e) => {
    setIsAwayTeamSame(e.target.checked);
  }

  const handleChange = (e, newValue, fieldName) => {
    const name = fieldName || e.target.name;
    const value = newValue !== undefined ? newValue : e.target.value;
    setFixtureData({
      ...fixtureData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const fixtureRef = doc(collection(db, `clubs/${clubId}/fixtures`));
    const broadcastRef = doc(collection(db, 'broadcasts'));

    console.log({ fixtureRef });
    
    const auditData = {
      createdAt: serverTimestamp(),
      createdBy: user.uid,
      updatedAt: serverTimestamp(),    
      updatedBy: user.uid,
      isDeleted: false,
    };

    try {
      await runTransaction(db, async (transaction) => {
        transaction.set(fixtureRef, {
          ...fixtureData,
          broadcastRef: broadcastRef,
          ...auditData,
          auditing: auditData,
        });
        transaction.set(broadcastRef, {
          clubId: clubId,
          fixture: fixtureData,
          fixtureId: fixtureRef.id,
          fixtureRef: fixtureRef,
          ...auditData,
          auditing: auditData,
        });
      });
  
      console.log('Both fixture and broadcast documents created successfully');

      setFixtureId(fixtureRef.id);

      // redirect to the new fixture
      navigate(fixtureRef.id);

      // reset form after successful submission
      setFixtureData(fixtureDataDefault);
    } catch (error) {
      console.error('Transaction failed: ', error);
    }
  };

  const controlStyle = {
    mt: "0",
  }
  
  // placeholder component
  const Placeholder = ({ children, style }) => {
    const sx = {
      display: 'inline-block',
      minWidth: '5rem',
      p: '0rem 0.1rem',
      fontWeight: 'bold',
      borderBottom: '1px dashed #ccc',
      borderBottomColor: children ? '#333' : '#f00',
      ...style,
    }

    return (
      <Typography component="span" sx={sx}>
        {!children && (<>&nbsp;</>)}
        {children}
      </Typography>
    );
  }

  return (
    <>
      <Title>New Match</Title>

      <form onSubmit={handleSubmit}>
        <Sheet>
          <FormControl orientation="vertical" sx={controlStyle}>
            <Stack direction="row" justifyContent="space-between">
              <FormLabel>Home</FormLabel>
              <ClubChip
                club={club}
                isSelected={isClubHome}
                handleClick={handleClubClick}
                clickParams="home"
              />
            </Stack>
            <ClubSearch
              name="home"
              labelField="name"
              value={fixtureData.homeTeam}
              onChange={handleClubChange}
              sx={ isClubHome && { display: "none" }}
            />
          </FormControl>
          { homeTeamOptions && (
            <FormControl orientation="vertical" sx={controlStyle}>
              <FormLabel>Team</FormLabel>
              <Select 
                name="homeClubTeamIndex"
                defaultValue={fixtureData?.homeClubTeamIndex}
                onChange={(e, newValue) => handleClubTeamChange(e, newValue, 'home')}
              >
                {homeTeamOptions.map((label, index) => (
                  <Option key={index} value={index}>{label}</Option>
                ))}
                <Option key="other" value="" sx={{ fontStyle: "italic" }}>Other...</Option>
              </Select>
            </FormControl>
          )}
        </Sheet>
        <Sheet>
          <FormControl orientation="vertical" sx={controlStyle}>
            <Stack direction="row" justifyContent="space-between">
              <FormLabel>Away</FormLabel>
              <ClubChip
                club={club}
                isSelected={isClubAway}
                handleClick={handleClubClick}
                clickParams="away"
              />
            </Stack>
            <ClubSearch
              name="away"
              labelField="name"
              value={fixtureData.awayTeam}
              onChange={handleClubChange}
              sx={ isClubAway && { display: "none" }}
            />
          </FormControl>
          { homeTeamOptions && awayTeamOptions && fixtureData.homeClubTeam && (
            <FormControl orientation="horizontal" sx={{ ...controlStyle, justifyContent: "left" }}>
              <Switch
                name="isAwayTeamSame"
                checked={isAwayTeamSame}
                label="Same as home team"
                variant={isAwayTeamSame ? 'solid' : 'outlined'}
                onChange={handleAwayTeamSwitch}
                size="lg"
                sx={{ mr: '0.5rem' }}
                />
              <FormLabel>{fixtureData.homeClubTeam}</FormLabel>
            </FormControl>
          )}
          { (!isAwayTeamSame || !homeTeamOptions) && awayTeamOptions && (
            <FormControl orientation="vertical" sx={controlStyle}>
              <FormLabel>Team</FormLabel>
              <Select
                name="awayClubTeamIndex"
                defaultValue={fixtureData?.awayClubTeamIndex}
                onChange={(e, newValue) => handleClubTeamChange(e, newValue, 'away')}
              >
                {awayTeamOptions.map((label, index) => (
                  <Option key={index} value={index}>{label}</Option>
                ))}
                <Option key="other" value="" sx={{ fontStyle: "italic" }}>Other...</Option>
              </Select>
            </FormControl>
          )}
        </Sheet>
        { fixtureData.homeTeam && fixtureData.awayTeam && (
          <>
            <Sheet>
              { !fixtureData?.homeClubTeam && !fixtureData?.awayClubTeam && (
                <>
                  <FormControl orientation="vertical" sx={controlStyle}>
                    <FormLabel>Gender</FormLabel>
                    <Autocomplete
                      freeSolo
                      openOnFocus
                      autoComplete
                      autoHighlight
                      blurOnSelect
                      disableClearable
                      placeholder="Select or type..."
                      name="gender"
                      value={fixtureData.gender}
                      onChange={(event, newValue) => handleChange(event, newValue, "gender")}
                      onInputChange={(event, newValue) => handleChange(event, newValue, "gender")}
                      onSelect={(event, newValue) => handleChange(event, newValue, "gender")}
                      options={genderOptions}
                      />
                  </FormControl>
                  <FormControl orientation="vertical" sx={controlStyle}>
                    <FormLabel>Grade</FormLabel>
                    <Autocomplete
                      freeSolo
                      openOnFocus
                      autoComplete
                      autoHighlight
                      blurOnSelect
                      disableClearable
                      placeholder="Select or type..."
                      name="grade"
                      value={fixtureData.grade}
                      onChange={(event, newValue) => handleChange(event, newValue, "grade")}
                      onInputChange={(event, newValue) => handleChange(event, newValue, "grade")}
                      onSelect={(event, newValue) => handleChange(event, newValue, "grade")}
                      options={gradeOptions}
                      required
                      />
                  </FormControl>
                </>
              )}
              <FormControl orientation="vertical" sx={controlStyle}>
                <FormLabel>Venue</FormLabel>
                <Input type="text" name="venue" value={fixtureData.venue} onChange={handleChange} required />
              </FormControl>
              <FormControl orientation="vertical" sx={controlStyle}>
                <FormLabel>Scheduled</FormLabel>
                <Input type="datetime-local" name="scheduledAt" value={fixtureData.scheduledAt} onChange={handleChange} required />
              </FormControl>
              <FormControl orientation="vertical" sx={controlStyle}>
                <FormLabel>Quarter length</FormLabel>
                <Input
                  type="number"
                  name="quarterLength"
                  value={fixtureData.quarterLength}
                  onChange={handleChange}
                  required
                  sx={{ width: '8rem', alignContent: 'center' }}
                  endDecorator={<>minutes</>}
                />
              </FormControl>
            </Sheet>
            
            <Sheet>
              <AccordionGroup>
                <Accordion>
                  <AccordionSummary>Edit additional details...</AccordionSummary>
                  <AccordionDetails>
                    <FormControl orientation="vertical" sx={controlStyle}>
                      <FormLabel>Round</FormLabel>
                      <Input type="text" name="round" value={fixtureData.round} onChange={handleChange} />
                    </FormControl>
                  </AccordionDetails>
                </Accordion>
              </AccordionGroup>
            </Sheet>

            <Sheet>
              <Stack direction="column" sx={{ textAlign: 'center' }}>
                <div>
                  <Placeholder>{fixtureData.gender} {formatGrade(fixtureData.grade)}</Placeholder>
                </div>
                { fixtureData.round && (
                  <div>
                    Round <Placeholder>{fixtureData.round}</Placeholder>
                  </div>
                )}
                <div>
                  <Placeholder>{fixtureData.homeTeam}</Placeholder>
                </div>
                <Stack direction="row" justifyContent="center" alignItems="center" spacing={0} sx={{ m: "0.5rem 0"}}>
                  <TeamAvatar team={fixtureData.homeClub?.name} src={fixtureData.homeClub?.imageUrl} />
                  <Typography component="em" sx={{ m: "0 1rem" }}>versus</Typography>
                  <TeamAvatar team={fixtureData.awayClub?.name} src={fixtureData.awayClub?.imageUrl} />
                </Stack>
                <div>
                  <Placeholder>{fixtureData.awayTeam}</Placeholder>
                </div>
                <div>at <Placeholder>{fixtureData.venue}</Placeholder></div>
                <div>{formatDateRelative(fixtureData.scheduledAt, { isTodayExplicit: true })}</div>
              </Stack>
            </Sheet>
            <FormControl orientation="horizontal" sx={{ ...controlStyle, justifyContent: "center" }}>
              <Button variant="solid" color="primary" type="submit">Create Match</Button>
              <Button variant="solid" color="secondary" component={RouterLink} to="/">Cancel</Button>
            </FormControl>
          </>
        )}
      </form>
    </>
  );
};

export default FixtureForm;