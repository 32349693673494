import { Typography } from "@mui/joy";
import Sheet from "../../common/Sheet";
import Link from "../common/Link";

export const TermsOfService = () => {

  return (
    <Sheet title="Terms of Service">
      <Typography level="body-md">
        Effective date: 16th July, 2024
      </Typography>
      
      <Typography level="body-sm" component="p">
        Welcome to pointpost. By using our app, you agree to these Terms of Service. Please read them carefully.
      </Typography>

      <Typography level="body-md">Use of Our Service</Typography>
      <Typography level="body-sm" component="ol">
        <li><strong>Eligibility:</strong> You must be at least 18 years of age to use this app.</li>
        <li><strong>Account Responsibility:</strong> You are responsible for maintaining the confidentiality of your account and password.</li>
        <li><strong>Acceptable Use:</strong> You agree not to use our service for any unlawful or prohibited activities.</li>
      </Typography>

      <Typography level="body-md">Content</Typography>
      <Typography level="body-sm" component="ol">
        <li><strong>Your Content:</strong> You retain ownership of any content you submit, post, or display on or through the app.</li>
        <li><strong>Our Rights:</strong> We have the right to remove any content that violates these terms or is deemed inappropriate.</li>
      </Typography>

      <Typography level="body-md">Termination</Typography>
      <Typography level="body-sm" component="p">
        We reserve the right to terminate or suspend your account at any time, without prior notice, for conduct that we believe violates these Terms of Service or is harmful to other users of the app.
      </Typography>

      <Typography level="body-md">Limitation of Liability</Typography>
      <Typography level="body-sm" component="p">
        We will not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly.
      </Typography>

      <Typography level="body-md">Changes to These Terms</Typography>
      <Typography level="body-sm" component="p">
        We may update our Terms of Service from time to time. Any changes will be posted on this page with an updated effective date.
      </Typography>

      <Typography level="body-md">Contact Us</Typography>
      <Typography level="body-sm" component="p">
        If you have any questions or concerns about these Terms of Service, please email <Link to="mailto:legal@pointpost.app">legal@pointpost.app</Link>.
      </Typography>
    </Sheet>
  );
}