import CloudOff from '@mui/icons-material/CloudOff';
import React, { useState, useEffect } from 'react';

const Offline = ({ style }) => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true);
    };

    const handleOffline = () => {
      setIsOnline(false);
      console.log('Offline!');
      playAlertSound();
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  const playAlertSound = async () => {
    const alertSound = new Audio('/sounds/material/ogg/02-alert/alert_high-intensity.ogg');

    try {
      await alertSound.play();
    } catch (error) {
      console.error('Error playing alert sound: ', error);
    }
  };

  const sx = {
    color: 'error.main',
    fontSize: '0.5rem',
    ...style,
  }

  return (
    <div>
      {!isOnline && (
        <CloudOff sx={sx} />
      )}
    </div>
  );
};

export default Offline;