import { useEffect, useMemo, useState } from "react";
import { collection, doc, getDoc, setDoc } from "firebase/firestore";
import { db, storage } from "../firebaseConfig";
import { useAuth } from "../hooks/useAuth";
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { useNavigate, useParams } from "react-router-dom";
import withAdminAuth from "../hoc/withAdminAuth";
import { Accordion, AccordionDetails, AccordionGroup, AccordionSummary, Button, FormControl, FormLabel, Input } from "@mui/joy";
import ClubSearch from "../clubs/ClubSearch";
import Sheet from "../common/Sheet";
import useLocalStorage from "../hooks/useLocalStorage";

export const ClubForm = withAdminAuth(() => {

  const { clubId } = useParams();
  const { user } = useAuth();

  const defaults = useMemo(() => ({
    name: '',
    clubName: '',
    initials: '',
    nickname: '',
    shortName: '',
    threeCharName: '',
    venue: '',
    venueAddress: '',
    websiteUrl: '',
    facebookUrl: '',
    grades: ['A', 'A Reserves'],
  }), []);

  const [cachedClub, setCachedClub] = useLocalStorage('club', null);
  const [formFields, setFormFields] = useState(defaults);
  const [selectedImage, setSelectedImage] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState('');
  
  useEffect(() => {
    if (!clubId) return;

    // load club
    const fetchClub = async () => {
      try {
        const docRef = doc(db, 'clubs', clubId);
        const docSnap = await getDoc(docRef);
        setFormFields({ ...defaults, ...docSnap.data() });
      } catch (error) {
        console.error('Error fetching club:', error);
      }
    };

    fetchClub();
  }, [clubId, defaults]);

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!user) {
      console.error('Save failed: user is not authenticated');
      return;
    } else if (!user?.isAdmin) {
      console.error('Save failed: user must be an admin');
      return;
    }
    
    setUploading(true);    

    async function uploadImage(file, id) {
      let imageUrl = '';
      try {
        const storageRef = ref(storage, `clubs/${id}.png`);
        await uploadBytes(storageRef, file);
        imageUrl = await getDownloadURL(storageRef);
      } catch (error) {
        console.error('Error uploading image:', error);
      }
      return imageUrl;
    }

    try {
      const { image, ...formFieldsToSave } = formFields;

      const clubData = {
        ...formFieldsToSave,
        uid: user.uid,
      };

      console.log({ clubData });

      let docRef;
      if (clubId) {
        docRef = doc(db, 'clubs', clubId);
      } else {
        docRef = doc(collection(db, 'clubs'));
      }

      // upload image (if provided, otherwise keep existing)
      let imageUrl = (!!image) ? await uploadImage(image, docRef.id) : (formFields.imageUrl || '');

      // save
      await setDoc(docRef, { ...clubData, imageUrl }, { merge: true });

      if (cachedClub?.id === clubId) {
        // clear club cache to force re-fetch of full document
        setCachedClub(null);
      }

      // navigate to club page
      navigate(`/club/${docRef.id}`);
    } catch (error) {
      console.error('Error saving club:', error);
      alert('Error saving club');
    } finally {
      setUploading(false);
    }
  };

  const handleChange = (e) => {
    setFormFields({
      ...formFields,
      [e.target.name]: e.target.value,
    });
  };

  const handleClubNameBlur = (e) => {
    const clubName = e.target.value;
    if (!clubName) return;

    const initials = clubName.split(' ').map(word => word[0]).join('');
    const firstWord = clubName.split(' ')[0];

    setFormFields({
      ...formFields,
      initials: formFields.initials || initials,
      name: formFields.name || firstWord,
    });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type === 'image/png') {
      // display preview of selected image
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);

      setFormFields({ ...formFields, image: file });
      setError(null);
    } else {
      setFormFields({ ...formFields, image: null });
      setError('Please upload a .png file.');
    }    
  };

  const handleClubLoad = (selected) => {
    navigate(`/club/${selected.id}/edit`);
  };

  const controlStyle = {
    mt: '0rem',
  }

  return (
    <>
      <Sheet>
        <ClubSearch isButton={true} buttonLabel="Edit" handleButton={handleClubLoad} />
      </Sheet>
      <form onSubmit={handleSubmit}>
        <Sheet>
          <FormControl orientation="vertical" sx={controlStyle}>
            <FormLabel>Club name</FormLabel>
            <Input
              name="clubName"
              type="text"
              placeholder="e.g. My Neighbourhood Football Club"
              value={formFields.clubName}
              onChange={handleChange}
              onBlur={handleClubNameBlur}
              required
            />
          </FormControl>
          <FormControl orientation="vertical" sx={controlStyle}>
            <FormLabel>Team name</FormLabel>
            <Input
              name="name"
              type="text"
              placeholder="e.g. My Neighbourhood"
              value={formFields.name}
              onChange={handleChange}
              required
            />
          </FormControl>
          <FormControl orientation="vertical" sx={controlStyle}>
            <FormLabel>Club initials</FormLabel>
            <Input name="initials" type="text" placeholder="e.g. MNFC" value={formFields.initials} onChange={handleChange} required />
          </FormControl>
        </Sheet>

        <Sheet>
          <AccordionGroup>
            <Accordion>
              <AccordionSummary>More name details...</AccordionSummary>
              <AccordionDetails>
                <FormControl orientation="vertical" sx={controlStyle}>
                  <FormLabel>Nickname</FormLabel>
                  <Input name="nickname" type="text" placeholder="e.g. Horses" value={formFields.nickname} onChange={handleChange} />
                </FormControl>
                <FormControl orientation="vertical" sx={controlStyle}>
                  <FormLabel>Short name</FormLabel>
                  <Input name="shortName" type="text" placeholder="e.g. My Neighbours" value={formFields.shortName} onChange={handleChange} />
                </FormControl>
                <FormControl orientation="vertical" sx={controlStyle}>
                  <FormLabel>
                    <abbr style={{textUnderlineOffset: "0.2rem"}} title="Three-character abbreviated name">3CN</abbr>
                  </FormLabel>
                  <Input
                    name="threeCharName"
                    type="text"
                    placeholder="MNB"
                    value={formFields.threeCharName}
                    onChange={handleChange}
                    slotProps={{ input: { maxLength: 3 }}}
                  />
                </FormControl>
              </AccordionDetails>
            </Accordion>
          </AccordionGroup>
        </Sheet>

        <Sheet>
          <FormControl orientation="vertical" sx={controlStyle}>
            <FormLabel>Venue</FormLabel>
            <Input
              name="venue"
              type="text"
              placeholder="e.g. My Neighbourhood Sporting Ground"
              value={formFields.venue}
              onChange={handleChange}
            />
          </FormControl>
          <FormControl orientation="vertical" sx={controlStyle}>
            <FormLabel>Address</FormLabel>
            <Input
              name="venueAddress"
              type="text"
              placeholder="e.g. 123 Neighbourhood Avenue"
              value={formFields.venueAddress}
              onChange={handleChange}
            />
          </FormControl>
        </Sheet>

        <Sheet>
          <AccordionGroup>
            <Accordion>
              <AccordionSummary>Social...</AccordionSummary>
              <AccordionDetails>
                <FormControl orientation="vertical" sx={controlStyle}>
                  <FormLabel>Website</FormLabel>
                  <Input name="websiteUrl" type="text" placeholder="e.g. www.mnfc.club" value={formFields.websiteUrl} onChange={handleChange} />
                </FormControl>
                <FormControl orientation="vertical" sx={controlStyle}>
                  <FormLabel>Facebook</FormLabel>
                  <Input name="facebookUrl" type="text" placeholder="e.g. facebook.com/mnfc" value={formFields.facebookUrl} onChange={handleChange} />
                </FormControl>
              </AccordionDetails>
            </Accordion>
          </AccordionGroup>
        </Sheet>

        <Sheet>
          <FormControl orientation="vertical" sx={controlStyle}>
            <FormLabel>Club Emblem (.png)</FormLabel>
            <Input name="image" type="file" accept="image/png" onChange={handleImageChange} />
            {error && <p style={{ color: 'red' }}>{error}</p>}
            {selectedImage && <img src={selectedImage} alt="Preview" />}
            <p>{formFields?.imageUrl && <img src={formFields.imageUrl} alt="Club Emblem" />}</p>
          </FormControl>
        </Sheet>

        <FormControl orientation="vertical" sx={controlStyle}>
          <Button type="submit" disabled={uploading}>
            {uploading ? 'Uploading...' : 'Save Club'}
          </Button>
        </FormControl>
      </form>
    </>
  );
});
