import React, { useEffect, useState } from 'react';
import { useFixture } from './FixtureContext';
import { TeamEmblem } from '../common/TeamEmblem';
import './Goal.css';

const Goal = ({ isStarted, animation, onFinish }) => {
  const [isPreloading, setIsPreloading] = useState(true);
  const [isFinished, setIsFinished] = useState(false);
  const { fixture } = useFixture();

  console.log('goal animation fixture', { fixture });

  const teamName = fixture?.[animation.team + 'Team'];
  const imgSrc = fixture?.[animation.team + 'Club']?.imageUrl;

  useEffect(() => {
    if (!isStarted) return;

    const prepTimer = setTimeout(() => {
      console.log('goal animation prep finishing...');
      setIsPreloading(false);

      const mainTimer = setTimeout(() => {
        console.log('goal animation finishing...');
        setIsFinished(true);
        onFinish();
      }, 9500);

      return () => clearTimeout(mainTimer);
    }, 2000);

    return () => clearTimeout(prepTimer);
  }, [isStarted, onFinish]);

  if (!isStarted || isPreloading || isFinished) return;

  console.log('goal animation starting...', {animation});

  return (
    <div className="goal-container">
      <div className="goal-content">
        { teamName && <TeamEmblem src={imgSrc} team={teamName} style={{ maxHeight: '1.8rem', pr: '0.2rem' }} /> }
        GOAL
      </div>
    </div>
  );
};

export default Goal;