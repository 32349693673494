import React from 'react';
import { Business } from './Business';
import { Privacy } from './Privacy';
import { TermsOfService } from './TermsOfService';
import { DataDeletion } from './DataDeletion';

export const Legal = () => {

  return (
    <>
      <Business />
      <Privacy />
      <TermsOfService />
      <DataDeletion />
    </>
  );
}