import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import CircularProgress from "../common/CircularProgress";

export const ProtectedRoute = ({ children }) => {
  const { user, isAuthLoading } = useAuth();

  if (isAuthLoading) {
    return <CircularProgress />;
  }

  if (!user) {
    return <Navigate to="/" />;
  }

  return children;
};
