import ModalCloseJoy from "@mui/joy/ModalClose";

export const ModalClose = () => {
  return (
    <ModalCloseJoy variant="outlined" sx={{
      top: 'calc(-1/4 * var(--IconButton-size))',
      right: 'calc(-1/4 * var(--IconButton-size))',
      boxShadow: '0 2px 12px 0 rgba(0 0 0 / 0.2)',
      borderRadius: '50%',
      bgcolor: 'background.body',
    }} />
  );
};