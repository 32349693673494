import React from 'react';
import { Stack, Typography } from '@mui/joy';
import { TeamAvatar } from '../common/TeamAvatar';
import CurrentTime from '../scoreboard/CurrentTime';
import { formatDateRelative } from '../helpers/DateTime';
import { formatGender } from '../helpers/Gender';
import { formatGrade } from '../helpers/Grade';

const avatarStyle = {
  "--Avatar-size": "4rem",
}

export const Matchup = ({ match }) => {

  if (!match) return "Loading match...";

  return (
    <>
      <Stack direction="column" display="flex" alignItems="center" justifyContent="center">
        <CurrentTime sx={{ fontSize: '1.2rem', m: '0.5rem 0'}} />
        <Typography mb="0rem" fontWeight="bold">{formatDateRelative(match.scheduledAt)}</Typography>
        <Typography mb="1.5rem">{formatGender(match.gender)} {formatGrade(match.grade)}</Typography>
        <Typography fontWeight="bold" sx={{ lineHeight: '1.5rem' }}>{match.homeTeam}</Typography>
        <Stack direction="row" justifyContent="center" alignItems="center" spacing={2} sx={{ m: "1rem 0" }}>
          <TeamAvatar src={match?.homeClub?.imageUrl} team={match?.homeTeam} sx={avatarStyle} />
          <Typography fontSize="sm" mt="1rem" mb="1rem">versus</Typography>
          <TeamAvatar src={match?.awayClub?.imageUrl} team={match?.awayTeam} sx={avatarStyle} />
        </Stack>
        <Typography fontWeight="bold" sx={{ lineHeight: '1.5rem' }}>{match.awayTeam}</Typography>
      </Stack>
    </>
  );
};
