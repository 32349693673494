import React from 'react';
import { Box, Button, Typography } from "@mui/joy";
import { Link, isRouteErrorResponse, useRouteError } from "react-router-dom";
import "./fonts/MuseoModerno.css"; 
import styles from "./css/RouteBoundary.module.css";

export const RootBoundary = () => {
  const error = useRouteError();

  const getMessage = () => {
    if (isRouteErrorResponse(error)) {
      if (error.status === 404) {
        return "not found 👀 we can't find what you were asking for";
      }

      if (error.status === 401) {
        return "not authorised ⛔️ you need to be logged in to see what you were hoping to see";
      }

      if (error.status === 403) {
        return "forbidden 🚷 you're not allowed to see what you were hoping to see";
      }

      if (error.status === 503) {
        return "app error 🔥 seems like this is our fault";
      }

      if (error.status === 418) {
        return "time for a 🫖 cuppa";
      }
    }

    return "unknown issue 🤷 something went wrong";
  };

  return (
    <Box sx={{ p: "2rem" }}>
      <Typography level="h1" component="h1" className={styles.heading}>Error {error.status}</Typography>
      <Typography level="body1">{getMessage()}</Typography>
      { /* refresh button */ }
      <Button onClick={() => window.location.reload()} sx={{ mt: 2, backgroundColor: "#333" }}>
        Retry
      </Button>{" "}
      <Button component={Link} to="/" sx={{ mt: 2, backgroundColor: "#333" }}>
        Start Over
      </Button>
    </Box>
  );
}