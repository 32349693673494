import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { doc, onSnapshot, serverTimestamp, updateDoc } from 'firebase/firestore';
import { db } from '../firebaseConfig';

import useLocalStorage from '../hooks/useLocalStorage';
import { useAuth } from '../hooks/useAuth';
import VersionCheck from '../components/utils/VersionCheck';

import { EventTimeline } from './EventTimeline';
import { EventModal } from './EventModal';
import { Scorecard } from './Scorecard';
import { Matchup } from './Matchup';
import { getSecondsRemaining } from '../helpers/GetSecondsRemaining';
import { ScoreboardButton } from './ScoreboardButton';
import { Grid, Stack, Button, Tabs, TabList, Tab, TabPanel, Select, Option, Alert } from '@mui/joy';
import { Animation, Delete, Edit, Forum, HelpOutline, NotStarted, RestoreFromTrash, Timeline } from '@mui/icons-material';
import { GoalForm } from './GoalForm';
import { HelpScorer } from '../components/club/HelpScorer';
import CircularProgress from '../components/common/CircularProgress';

export const Match = () => {
  const { user } = useAuth();
  const [loadError, setLoadError] = useState(null);
  const [fixture, setFixture] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [isEventLoading, setIsEventLoading] = useState(false);
  const [openEventModal, setOpenEventModal] = useState(false);
  const [eventModalData, setEventModalData] = useState(null);
  const [eventModalAction, setEventModalAction] = useState(null);
  const [animation, setAnimation] = useState({
    type: 'goal',
    team: 'home',
    option: 'default',
  });
  const [isAnimationLoading, setIsAnimationLoading] = useState(false);

  const navigate = useNavigate();

  const { clubId, fixtureId } = useParams();
  
  const fixturePath = `clubs/${clubId}/fixtures/${fixtureId}`;

  const [cachedFixturePath, setCachedFixturePath] = useLocalStorage('fixturePath', null);
  const [cachedFixture, setCachedFixture] = useLocalStorage('fixture', null);

  useEffect(() => {
    if (fixturePath !== cachedFixturePath) {
      console.log('Clearing cached fixture, loading new fixture: ', fixturePath);
      setCachedFixture(null);
      setCachedFixturePath(fixturePath);
    } else if (fixture && !cachedFixture) {
      // cache fixture locally for other components
      console.log('Caching fixture: ', fixture);
      setCachedFixture(fixture);
    }
  }, [fixture, fixturePath, cachedFixturePath, setCachedFixturePath, cachedFixture, setCachedFixture]);

  useEffect(() => {
    const fixtureDefaults = {
      grade: '',
      round: '',
      homeTeam: 'North Fremantle',
      awayTeam: '',
      venue: 'Gil Fraser Reserve',
      scheduledAt: null,
      quarterLength: 25,
    };

    const docRef = doc(db, fixturePath);

    const unsubscribe = onSnapshot(docRef, (docSnap) => {
      if (docSnap.exists()) {
        console.log('fixture: ', docSnap.data());
        const updatedFixture = {
          id: docSnap.id,
          clubId: clubId,
          ...fixtureDefaults,
          ...docSnap.data(),
        };
        setFixture(updatedFixture);
      } else {
        console.error("Error retrieving match: ", docRef);
        setLoadError("Match failed to load");
      }
    });

    return () => unsubscribe();
  }, [clubId, fixturePath]);

  if (loadError) {
    return <Alert variant="soft" color="danger">{loadError}</Alert>;
  }

  if (!fixture) {
    return <CircularProgress />;
  }

  const eventModal = (event, action = 'create') => {
    console.log('eventModal: ', {event}, {fixture});
    console.log('seconds remaining: ', getSecondsRemaining(fixture));
    const eventStub = {
      ...event,
      secondsRemaining: getSecondsRemaining(fixture),
    };

    // open the modal
    setEventModalData(eventStub);
    setEventModalAction(action);
    setOpenEventModal(true);
  }
  
  const handleEventModalClose = (e, reason) => {
    if (reason !== 'backdropClick') setOpenEventModal(false);
  }

  const startMatch = () => {
    eventModal({
      type: 'start',
      label: 'Match ready',
      quarter: 'Q1',
      quarterLength: fixture.quarterLength,
    });
  }

  const handleEdit = () => {
    setIsEditing(!isEditing);
  }

  const handleDelete = () => {
    console.log('delete fixture: ', fixture);

    const docRef = doc(db, `clubs/${clubId}/fixtures/${fixtureId}`);

    try {
      updateDoc(docRef, {
        auditing: {
          isDeleted: true,
          deletedAt: serverTimestamp(),
          deletedBy: user.uid,
        }
      });
      console.log('fixture deleted: ', fixtureId);
    } catch (error) {
      console.error('Error deleting fixture: ', error);
    };
  }

  const handleUndelete = () => {
    console.log('restoring fixture: ', fixture);

    const docRef = doc(db, `clubs/${clubId}/fixtures/${fixtureId}`);

    try {
      updateDoc(docRef, {
        auditing: {
          isDeleted: false,
          restoredAt: serverTimestamp(),
          restoredBy: user.uid,
        }
      });
      console.log('fixture restored: ', fixtureId);
    } catch (error) {
      console.error('Error restoring fixture: ', error);
    };
  }

  const broadcastUri = fixture?.broadcastRef?.id ? `/broadcast/${fixture.broadcastRef.id}` : null;
  const handleBroadcastClick = () => {
    navigate(broadcastUri);
  }

  const changeAnimation = (action, animation) => {
    console.log('updateAnimation: ', {action}, {animation});
    setAnimation(animation);
  }

  const updateAnimation = () => {
    // update fixture's animation

    setIsAnimationLoading(true);

    const docRef = doc(db, fixturePath);

    try {
      updateDoc(docRef, {
        animation: {
          ...animation,
          key: (Math.random() + 1).toString(36).substring(2, 15),
        }
      });
      console.log('animation updated!');
    } catch (error) {
      console.error('Error updating animation: ', error);
    } finally {
      const loader = setTimeout(() => {
        setIsAnimationLoading(false);
        return () => clearTimeout(loader);
      }, 2000);
    }
  }

  return (
    <>
      <EventModal
        isOpen={openEventModal}
        handleClose={handleEventModalClose}
        eventStub={eventModalData}
        action={eventModalAction}
        fixture={fixture}
        setFixture={setFixture}
        setIsEventLoading={setIsEventLoading}
      />
      <VersionCheck />
      {!fixture.isStarted ? (
        <>
          <Stack direction="row" justifyContent="space-between">
            <Button variant="solid" onClick={startMatch}><NotStarted sx={{ mr: '0.3rem' }} />Ready...</Button>
            <Stack direction="row" spacing={1}>
              { broadcastUri && (
                <Button variant="plain" onClick={handleBroadcastClick}>
                  <Forum fontSize="md" />
                </Button>
              )}
              <ScoreboardButton match={fixture} isPulsing />
            </Stack>
          </Stack>
          <Matchup match={fixture} eventModal={eventModal} />
        </>
      ) : (
        <Grid container spacing={2} alignItems="flex-start">

          <Grid xs={12} md={6}>
            <Scorecard match={fixture} eventModal={eventModal} />
          </Grid>

          <Grid xs={12} md={6}>

            <Tabs aria-label="Match details" size="sm">
              <TabList sticky="top" tabFlex={1}>
                <Tab><Timeline fontSize="xs" /> Timeline</Tab>
                <Tab><Animation fontSize="xs" /> Animation</Tab>
                <Tab><HelpOutline fontSize="xs" /> Help</Tab>
              </TabList>
              <TabPanel value={0}>
                { isEventLoading && <CircularProgress sx={{ height: "5vh" }} />}
                <Stack direction="row" justifyContent="right">
                  <Button 
                    variant={isEditing ? "solid" : "outlined"}
                    size="xs"
                    color="primary"
                    onClick={handleEdit}
                  >
                    <Edit fontSize="sm" sx={{ mr: '0.3rem' }} /> Edit
                  </Button>
                </Stack>
                <EventTimeline match={fixture} eventModal={eventModal} isEditing={isEditing} />
              </TabPanel>
              <TabPanel value={1}>
                <Stack direction="column" justifyContent="center" spacing={2}>
                  <Select defaultValue="home">
                    <Option value="home">Home</Option>
                  </Select>
                  <GoalForm
                    event={{ isAnimated: true, team: 'home', animation: animation }}
                    optionDefault={animation?.option}
                    handleChange={changeAnimation}
                    isProtected={false}
                  />
                  <Button
                    variant="solid"
                    loading={isAnimationLoading}
                    onClick={updateAnimation}
                  >
                    <Animation sx={{ mr: "0.2rem "}} /> Send Animation
                  </Button>
                </Stack>
              </TabPanel>
              <TabPanel value={2}>
                <HelpScorer />
              </TabPanel>
            </Tabs>
          </Grid>
        </Grid>
      )}

      { user?.isAdmin && (
        <Stack direction="row" spacing={1} justifyContent="center" sx={{ mt: '1.5rem' }}>
          { !fixture?.auditing?.isDeleted ? (
            <Button variant="soft" color="danger" onClick={handleDelete}>
              <Delete fontSize="sm" sx={{ mr: "0.2rem" }} /> Delete Match
            </Button>
          ) : (
            <Button variant="soft" color="neutral" onClick={handleUndelete}>
              <RestoreFromTrash fontSize="sm" sx={{ mr: "0.2rem" }} /> Restore Match
            </Button>
          )}
        </Stack>
      )}

    </>
  );
};