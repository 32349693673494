import React, { useEffect, useState } from 'react';

import { getSecondsRemaining } from '../../helpers/GetSecondsRemaining';
import { Typography } from '@mui/joy';

const ScoreboardTimer = ({ isLive, match, fontSize = "lg", sx }) => {
  const [secondsOn, setSecondsOn] = useState(0);

  useEffect(() => {
    // update the timer
    let timeout;
    
    const updateTimer = () => {
      const secondsRemaining = getSecondsRemaining(match);
      if (secondsRemaining !== null) {
        setSecondsOn(secondsRemaining);
      }
      if (isLive && match.timerIsRunning && secondsRemaining > 0) {
        // update the timer every 100ms
        timeout = setTimeout(updateTimer, 100);
      }
    }

    if (match !== null) updateTimer();

    return () => {
      clearTimeout(timeout);
    };
  }, [isLive, match]);

  const formatTime = (secondsOn) => {
    const minutes = Math.floor(secondsOn / 60);
    const seconds = secondsOn % 60;

    return `${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  }

  const timerStyle = {
    fontWeight: 'bold',
    color: (secondsOn <= 120) ? '#f36' : 'white', // red time
    ...sx,
  }

  return (
    <Typography level="monospace" fontSize={fontSize} sx={timerStyle}>{formatTime(secondsOn)}</Typography>
  );
}

export default ScoreboardTimer;