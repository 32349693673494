import React from 'react';
import { Stack, Typography } from '@mui/joy';
import { TeamEmblem } from '../common/TeamEmblem';
import CurrentTime from '../scoreboard/CurrentTime';
import { formatDateRelative } from '../helpers/DateTime';
import { formatGrade } from '../helpers/Grade';

export const Matchup = ({ match }) => {

  if (!match) return "Loading match...";

  const emblemStyle = {
    maxHeight: '0.7rem',
    mr: '0.5rem',
  }

  const teamStyle = {
    lineHeight: '1',
    textTransform: 'uppercase',
    fontSize: '0.85rem',
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
  }

  const detailsStyle = {
    lineHeight: '1.1',
    fontSize: '0.75rem',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }

  return (
    <>
      <Stack direction="column" display="flex">
        <CurrentTime sx={{ textAlign: 'left', fontSize: '1.2rem', mb: '0rem' }} />
        <Typography sx={teamStyle}>
          <TeamEmblem src={match?.homeClub?.imageUrl} team={match?.homeTeam} style={emblemStyle} /> {match.homeTeam}
        </Typography>
        <Typography sx={teamStyle}>
          <TeamEmblem src={match?.awayClub?.imageUrl} team={match?.awayTeam} style={emblemStyle} placeholder={false} /> {match.awayTeam}
        </Typography>
        <Typography sx={detailsStyle}>
          {match.gender && (match.gender + ' ')}
          {formatGrade(match.grade)}<br />
        </Typography>
        <Typography sx={detailsStyle}>
          {formatDateRelative(match.scheduledAt)}
        </Typography>
        { match.venue !== 'Gil Fraser Reserve' && (
          <Typography sx={detailsStyle}>
            {match.venue} 
          </Typography>
        )}
      </Stack>
    </>
  );
};
