import React, { useState } from 'react';
import { useAuth } from './hooks/useAuth';
import { getAuth, FacebookAuthProvider, GoogleAuthProvider, signInWithRedirect } from "firebase/auth";
import { Accordion, AccordionDetails, AccordionGroup, AccordionSummary, Button, Typography } from '@mui/joy';
import Sheet from './common/Sheet';
import GoogleIcon from './common/GoogleIcon';
import { isMobile } from 'react-device-detect';
import Link from './components/common/Link';
import { Facebook } from '@mui/icons-material';
import CircularProgress from './components/common/CircularProgress';

const auth = getAuth();

const SignIn = ({ isFacebookEnabled = true }) => {
  const { user, wasAdmin, isAuthLoading } = useAuth();
  const [isFacebookLoading, setIsFacebookLoading] = useState(false);
  const [isGoogleLoading, setIsGoogleLoading] = useState(false);

  const handleGoogleSignIn = () => {
    setIsGoogleLoading(true);
    const googleProvider = new GoogleAuthProvider();
    signInWithRedirect(auth, googleProvider);
  };

  const handleFacebookSignIn = () => {
    setIsFacebookLoading(true);
    const facebookProvider = new FacebookAuthProvider();
    signInWithRedirect(auth, facebookProvider);
  }

  if (user) return;

  if (isAuthLoading) return (
    <CircularProgress />
  )

  return (
    <Sheet title="Welcome">
      <Typography level="body-sm">
        Sign in to continue.
      </Typography>

      { (isFacebookEnabled || wasAdmin) && (
        <Button 
          variant="soft"
          color="neutral"
          onClick={handleFacebookSignIn}
          loading={isFacebookLoading}
          startDecorator={<Facebook />}
          sx={{ 
            color: "#fff",
            backgroundColor: "#1877F2",
            border: "1px solid #1877F2",
            '&:hover': {
              backgroundColor: "#1877F2",
            },
            '&:active': {
              backgroundColor: "#1877F2",
            },
          }}
        >
          Continue with Facebook
        </Button>
      )}

      <AccordionGroup size="sm">
        <Accordion sx={{ p: "0", m: "0" }}>
          <AccordionSummary>Other sign in options</AccordionSummary>
          <AccordionDetails>

            { isMobile && (
              <Typography level="body-sm">
                {" "}
                Get here from a link in an app?
                Open your favourite browser app and go to <Link to="https://pointpost.app/">pointpost.app</Link> for a more reliable experience.
              </Typography>
            ) }

            <Button 
              variant="outlined"
              color="primary"
              onClick={handleGoogleSignIn}
              loading={isGoogleLoading}
              startDecorator={<GoogleIcon />}
            >
                Continue with Google
            </Button>

          </AccordionDetails>
        </Accordion>
      </AccordionGroup>
    </Sheet>
  );
};

export default SignIn;