import React from 'react';
import { Grid } from '@mui/joy';
import Button from '@mui/joy/Button';
import { Score } from '../common/Score';

export const ScoreControls = ({ team, goals, behinds, eventModal }) => {

  const totalScore = (goals, behinds) => {
    return (goals * 6) + (behinds * 1);
  };

  const onClick = (team, key) => {
    eventModal({
      type: key.toLowerCase(),
      label: key,
        team: team,
    });
  };

  return (
    <>
      <Grid container spacing={2} sx={{ flexGrow: 1 }} alignItems="center" justifyContent="center">
        <Grid xs={3} display="flex" justifyContent="center">
          <Button variant="outlined" onClick={() => onClick(team, 'Goal')}>{goals}</Button>
        </Grid>
        <Grid xs={3} display="flex" justifyContent="center">
          <Button variant="outlined" onClick={() => onClick(team, 'Behind')}>{behinds}</Button>
        </Grid>
        <Grid xs={3} display="flex" justifyContent="center">
          <Score value={totalScore(goals, behinds)} style={{ fontSize: '1.3rem' }} />
        </Grid>
      </Grid>
    </>
  );
};
