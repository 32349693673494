import { format, isToday, isTomorrow, isSameYear, parseISO, isYesterday, isAfter, isBefore, addDays } from 'date-fns';

export const dateTimeString = (value) => {
  if (!value) return null;

  if (value instanceof Date) {
    // already a date object
    return value.toISOString();
  } else if (typeof value === 'string') {
    // already a string
    return value;
  } else {
    // force whatever it is into a date
    try {
      return value.toDate().toISOString();
    } catch (e) {
      console.error('Error converting to date:', e, { value });
      return null;
    }
  }
}

export const getTimeDiff = (dateVariant1, dateVariant2 = null) => {
  if (!dateVariant1) return;

  const date1 = parseISO(dateTimeString(dateVariant1));
  const date2 = dateVariant2 ? parseISO(dateTimeString(dateVariant2)) : new Date();

  const millis = Math.abs(date2 - date1);
  
  const seconds = Math.floor(millis / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  return { days, hours: hours % 24, minutes: minutes % 60, seconds: seconds % 60, milliseconds: millis };
};

export const formatDateRelative = (dateVariant, optionsProvided = null) => {
  if (!dateVariant) return;

  const dateString = dateTimeString(dateVariant);
  
  const options = {
    isTodayExplicit: false,
    isMidSentence: false,
    at: ' at',
    timeSeparator: '',
    dateFormat: 'EEEE do MMM',
    ...optionsProvided,
  }

  const sentenceCase = (str, options) => {
    const firstChar = options.isMidSentence ? str.charAt(0).toLowerCase() : str.charAt(0).toUpperCase();
    return firstChar + str.slice(1);
  }

  const today = new Date();
  const date = parseISO(dateString);
  const formattedTime = format(date, 'h:mmaaa');
  
  if (isToday(date)) {
    return (options.isTodayExplicit ? sentenceCase('Today', options) + `${options.at} ` : '') + formattedTime;
  } else if (isTomorrow(date)) {
    return sentenceCase('Tomorrow', options) + `${options.at} ` + formattedTime;
  } else if (isYesterday(date)) {
    return sentenceCase('Yesterday', options) + `${options.at} ` + formattedTime;
  } else if (isAfter(date, today) && isBefore(date, addDays(today, 7))) {
    const weekday = format(date, 'EEEE');
    return `${weekday}${options.at} ` + formattedTime;
  } else if (isSameYear(date, new Date())) {
    return formattedTime + options.timeSeparator + format(date, ' ' + options.dateFormat);
  } else {
    return formattedTime + options.timeSeparator + format(date, ' ' + options.dateFormat + ' yyyy');
  }
};
