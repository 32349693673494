import { Female, Male } from '@mui/icons-material';

export const formatGender = (gender, style = null) => {
  const sx = {
    fontSize: '1.1rem',
    pb: '0.1rem',
    mt: '0rem',
    mb: '-0.3rem',
    ...style
  };

  switch (gender.toLowerCase().replace(/s$/, '')) {
    case 'male':
    case 'men':
    case 'boy':
      return <Male sx={sx} />;
    case 'women':
    case 'girl':
    case 'female':
      return <Female sx={sx} />;
    default:
      return;
  }
}