import { useState } from "react";
import Sheet from "../../common/Sheet";
import { Button } from "@mui/joy";

export const TestSound = () => {

  const [audioContext, setAudioContext] = useState(null);

  const initAudio = () => {
    const context = new (window.AudioContext || window.webkitAudioContext)();
    setAudioContext(context);
  };

  const playSound = () => {
    if (audioContext) {
      const oscillator = audioContext.createOscillator();
      oscillator.connect(audioContext.destination);
      oscillator.start();
      oscillator.stop(audioContext.currentTime + 0.5); // Play for 0.5 seconds
    }
  };

  return (
    <Sheet title="Sound Test">
      <Button variant="outlined" onClick={initAudio}>Initialise</Button>
      <Button variant="outlined" onClick={playSound}>Play</Button>
    </Sheet>
  );
}
