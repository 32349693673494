import { db } from "../firebaseConfig";
import { useAuth } from '../hooks/useAuth';
import { useEffect, useState } from 'react';
import { useParams, Link as RouterLink, useLoaderData, useRouteError } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';

import { Button, Stack, Typography } from "@mui/joy";

import Title from '../common/Title';
import { Edit } from '@mui/icons-material';
import { TeamEmblem } from '../common/TeamEmblem';
import { ClubAdmin } from './ClubAdmin';
import { ClubFan } from './ClubFan';
import CircularProgress from "../components/common/CircularProgress";

export const Club = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [membership, setMembership] = useState(null);
  const { user } = useAuth();
  const club = useLoaderData();
  const error = useRouteError();
  const { clubId } = useParams();

  useEffect(() => {
    if (user && club && membership) {
      setIsLoading(false)
    }
  }, [user, club, membership]);

  useEffect(() => {
    if (!user) return;

    const fetchMembership = async (user, clubId) => {
      try {
        const docRef = doc(db, `clubs/${clubId}/members/${user.uid}`);
        const docSnap = await getDoc(docRef);
        setMembership({ ...docSnap.data(), id: docSnap.id });
      } catch (error) {
        console.error('Error fetching membership:', error);
      }
    }
  
    fetchMembership(user, clubId);
  }, [user, clubId]);

  if (error) {
    return (
      <div>
        <h1>Error</h1>
        <p>{error.statusText}</p>
      </div>
    );
  }
  
  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <>
      <Stack key={club.id} direction="column" alignItems="center" justifyContent="center" sx={{ m: "1.0rem 0", textAlign: "center" }}>
        <TeamEmblem team={club?.name} src={club?.imageUrl} style={{ height: "3rem", maxHeight: "3rem" }} />
        <Title sx={{ m: "0.5rem 0", lineHeight: "1.2rem" }}>{ club.name }</Title>
        <Typography fontSize="xs" sx={{ lineHeight: "1.0rem" }}>{ club?.clubName }</Typography>
        {user?.isAdmin && (
          <Button variant="solid" color="secondary" component={RouterLink} to={`/club/${clubId}/edit`}><Edit fontSize="sm" /> Edit</Button>
        )}
      </Stack>

      { user?.isAdmin || membership?.isApproved ? (
        <ClubAdmin club={club} />
      ) : (
        <ClubFan club={club} />
      )}
    </>
  );
};