import React from 'react';
import { Typography } from '@mui/joy';
import { Link } from 'react-router-dom';

const Title = ({ children, link, sx }) => {
  const Wrapper = link ? Link : React.Fragment;
  const wrapperProps = link ? { to: link } : {};

  return (
    <>
      <Wrapper {...wrapperProps}>
        <Typography level="h4" component="h2" sx={{ ...sx }}>
          {children}
        </Typography>
      </Wrapper>
    </>
  );
};

export default Title;