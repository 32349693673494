import { useParams, Link as RouterLink } from 'react-router-dom';
import FixturesList from './FixturesList';
import { Fab } from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';
import { Box } from '@mui/joy';

export const Fixtures = () => {

  const { clubId } = useParams();

  return (
    <Box sx={{ '& > :not(style)': { m: 0 } }}>
      <Fab color="primary" aria-label="add" component={RouterLink} to={`/club/${clubId}/fixture`}>
        <AddIcon />
      </Fab>
      <FixturesList clubId={clubId} />
    </Box>
  );
};