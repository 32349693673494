import React from 'react';
import ReactMarkdown from 'react-markdown';
import breaks from 'remark-breaks';
import rehypeSanitize from 'rehype-sanitize';

const MarkdownDisplay = ({ text }) => {
  return (
    <ReactMarkdown
      children={text}
      remarkPlugins={[breaks]}
      rehypePlugins={[rehypeSanitize]}
    />
  );
};

export default MarkdownDisplay;