import React, { useEffect, useState } from 'react';

export const TeamEmblem = ({ team = null, src = null, style = null, placeholder = false }) => {
  const [imgSrc, setImgSrc] = useState(src);

  useEffect(() => {
    if (!!src || !team) return;

    // TODO remove these emblem hacks
    const parseSrc = (team) => {
      const path = '/clubs/';
      switch (team) {
        case 'North Fremantle': return path + 'nffc.png';
        case 'Kardinya CBC': return path + 'cbc.png';
        case 'PFL All-Stars': return path + 'pfl.png';
        case 'Hills': return path + 'hills-fa.png';
        default: return path + convertToSlug(team) + '.png';
      }
    };

    setImgSrc(parseSrc(team));
  }, [src, team]);

  const styleCombined = {
    height: 'auto',
    maxHeight: '1.5rem',
    width: 'auto',
    maxWidth: '100%',
    margin: 'auto',
    ...style,
  };

  function convertToSlug(str) {
    return str?.toLowerCase().replace(/\s+/g, '-');
  }

  const handleImageError = (placeholder) => {
    if (placeholder) {
      setImgSrc('/logo.svg');
    } else {
      setImgSrc('/clubs/_blank.png');
    }
  };

  return (
    <img
      src={imgSrc}
      alt={team}
      style={styleCombined}
      onError={() => handleImageError(placeholder)}
    />
  );
};
