import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { db } from '../firebaseConfig';
import { doc, getDoc, onSnapshot } from 'firebase/firestore';
import { createGlobalStyle } from 'styled-components';
import '../css/scoreboard.css';

import { Box, CircularProgress, Grid, Stack, Typography } from '@mui/joy';
import CurrentTime from './CurrentTime';
import ScoreboardTimer from '../components/scoreboard/ScoreboardTimer';
import { TeamEmblem } from '../common/TeamEmblem';
import { Scores } from './Scores';
import { dateTimeString } from '../helpers/DateTime';
import { Matchup } from './Matchup';
import { SportsScoreRounded } from '@mui/icons-material';
import Offline from './Offline';
import Goal from './Goal';
import Video from './Video';
import { Feature } from './Feature';
import { useFixture } from './FixtureContext';
import MarkdownDisplay from '../common/MarkdownDisplay';

const ScoreboardStyle = createGlobalStyle`
  html {
    font-size: 8vw;
  }

  body {
    background-color: black !important;
    margin: 2%;
  }
`;

export const Scoreboard = ({ isEmbedded = false, isForcedLive = false, props = null, initScene = null, initContent = null }) => {
  const [isLive, setIsLive] = useState(isForcedLive);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [scene, setScene] = useState(initScene);
  const [scoreboard, setScoreboard] = useState(props);
  const [scoreboardLastUpdatedAt, setScoreboardLastUpdatedAt] = useState(null);
  const [match, setMatch] = useState(null);
  const [message, setMessage] = useState(null);
  const { setFixture } = useFixture();
  const [animationKeyQueued, setAnimationKeyQueued] = useState(null);
  const [animationKeyLoaded, setAnimationKeyLoaded] = useState(null);
  const [animationQueue, setAnimationQueue] = useState([]);
  const [animation, setAnimation] = useState(null);

  // this is for live scoreboards (not embedded previews)
  const { scoreboardId } = useParams();

  useEffect(() => {
    console.log('setting fixture global', match);
    setFixture(match);
  }, [match, setFixture]);

  useEffect(() => {
    if (scene || props === null) return;
    
    const fetchScoreboard = async (props) => {
      try {
        const ref = doc(db, `scoreboards/${props.id}`);
        const snap = await getDoc(ref);
        setScoreboard({ ...snap.data(), id: snap.id });
      } catch (error) {
        console.error('Error fetching scoreboard:', error);
        setIsError(true);
      }
    };

    fetchScoreboard(props);

  }, [props, scene]);

  useEffect(() => {
    if (!isEmbedded || (!initScene && !scoreboard?.sceneRef)) return;

    const fetchFixture = async (scoreboard) => {
      try {
        const fixtureRef = doc(db, `clubs/${scoreboard.clubId}/fixtures/${scoreboard.fixtureId}`);
        const fixtureSnap = await getDoc(fixtureRef);
        setMatch({ ...fixtureSnap.data(), id: fixtureSnap.id });
      } catch (error) {
        console.error('Error fetching fixture:', error);
        setIsError(true);
      }
    };

    const fetchMessage = async (scoreboard) => {
      try {
        const messageRef = doc(db, `clubs/${scoreboard.clubId}/messages/${scoreboard.messageId}`);
        const snap = await getDoc(messageRef);
        console.log('message:', snap.data());
        setMessage({ ...snap.data(), id: snap.id });
      } catch (error) {
        console.error('Error fetching message:', error);
        setIsError(true);
      }
    };

    const scoreboardScene = initScene ?? scoreboard.sceneRef?.path;

    if (isForcedLive) {
      // subscribe to the live match snapshot ($$$)
      setIsLive(true);
    } else if (scoreboardScene === 'scenes/match') {
      // get static match data and save $$$
      setScene('match');
      if (initContent) {
        setMatch(initContent);
      } else {
        fetchFixture(scoreboard);
      }
    } else if (scoreboardScene === 'scenes/message') {
      setScene('message');
      if (initContent) {
        setMessage(initContent);
      } else {
        fetchMessage(scoreboard);
      }
    }
  }, [scoreboard, isEmbedded, isForcedLive, initScene, initContent]);

  useEffect(() => {
    if (!scoreboardId) return;

    // subscribe to the scoreboard
    const docRef = doc(db, `scoreboards/${scoreboardId}`);
    const unsubscribe = onSnapshot(docRef, (snapshot) => {
      if (snapshot.exists()) {
        setScoreboard({
          id: snapshot.id,
          ...snapshot.data(),
        });
        setIsLive(true);
      } else {
        console.error(`No such scoreboard [${scoreboardId}]`);
        setIsError(true);
      }
    }, (error) => {
      console.error(`Error fetching scoreboard [${scoreboardId}]:`, error);
      setIsError(true);
    });

    return () => unsubscribe();
  }, [scoreboardId]);

  useEffect(() => {
    if (!scoreboard || !scoreboard?.updatedAt) return;

    if (!scoreboardLastUpdatedAt) {
      setScoreboardLastUpdatedAt(scoreboard.updatedAt);
    } else if (dateTimeString(scoreboardLastUpdatedAt) !== dateTimeString(scoreboard.updatedAt)) {
      // scoreboard change? reload the page!
      window.location.reload();
    }
  }, [scoreboard, scoreboardLastUpdatedAt]);

  useEffect(() => {
    if (!isLive || !scoreboard) return;

    if (scoreboard.sceneRef?.path === 'scenes/message') {
      setScene('message');
      // subscribe to the message
      const docRef = doc(db, `clubs/${scoreboard.clubId}/messages/${scoreboard.messageId}`);
      const messageUnsub = onSnapshot(docRef, (snapshot) => {
        if (snapshot.exists()) {
          setMessage({
            id: snapshot.id,
            ...snapshot.data(),
          });
        } else {
          console.error("No such message [" + scoreboard.messageId + "]");
          setIsError(true);
        }
      });

      return () => messageUnsub();
    } else if (scoreboard.fixtureId) {
      setScene('match');
      // subscribe to the match
      const docRef = doc(db, `clubs/${scoreboard.clubId}/fixtures/${scoreboard.fixtureId}`);
      const matchUnsub = onSnapshot(docRef, (snapshot) => {
        if (snapshot.exists()) {
          const updatedMatch = snapshot.data();
          if (!updatedMatch.animation?.key) {
            // no animation
            console.log('no animation');
          } else if (!animationKeyQueued) {
            // prevent animation on first load
            console.log('animation key set', updatedMatch.animation?.key);
            setAnimationKeyQueued(updatedMatch.animation?.key);
          } else if (updatedMatch.animation?.key !== animationKeyQueued) {
            // a fresh animation has been queued!
            console.log('animation queued', updatedMatch.animation);
            setAnimationQueue(animationQueue => [...animationQueue, updatedMatch.animation]);
            setAnimationKeyQueued(updatedMatch.animation.key);
          }
          setMatch({
            id: snapshot.id,
            ...updatedMatch,
            scheduledAt: dateTimeString(updatedMatch.scheduledAt),
          });
        } else {
          console.error("No such match [" + scoreboard.fixtureId + "]");
          setIsError(true);
        }
      });

      return () => matchUnsub();
    }
  }, [isLive, scoreboard, animationKeyQueued, animationQueue]);

  useEffect(() => {
    console.log('animation?', {animationKeyLoaded, animationQueue});
    if (
      animationQueue.length === 0
      || animationQueue[0].key === animationKeyLoaded
      ) return;
      
    // grab the next animation (fifo)
    const animation = animationQueue[0];

    console.log('performing animation', {animation});
    
    setAnimation(animation);
    setAnimationKeyLoaded(animation.key);

  }, [animationKeyLoaded, animationQueue]);

  if (isError) {
    return <Typography sx={{ m: "1rem" }}>❌</Typography>;
  }

  if (!scoreboard) {
    return <CircularProgress sx={{ m: "1rem" }} />;
  }

  if (
    isLoading && (
      (scene === 'match' && match) ||
      (scene === 'message' && message)
    )
  ) {
    setIsLoading(false);
  }

  if (isLoading) {
    return <CircularProgress sx={{ m: "1rem" }} />;
  }

  const containerStyle = {
    position: 'relative',
    width: '100%',
    height: '100%',
  }

  const top = Math.min(Math.max(scoreboard.top, -100), 100);
  const left = Math.min(Math.max(scoreboard.left, -100), 100);
  const width = Math.min(Math.max(scoreboard.width, 0.1), 2);
  const zoomFactor = Math.min(Math.max(scoreboard.scale, 0.1), 5);

  const scoreboardStyle = {
    position: 'absolute',
    top: top + 'vh',
    left: left + 'vw',
    width: `${width * 100}%`,
    opacity: Math.min(Math.max(scoreboard.dimmer, 0.1), 1),
    transform: `scale(${zoomFactor})`,
  }

  const rowStyle = {
    mt: '0.10rem',
    mb: '0.55rem',
    lineHeight: '1',
  }

  const startAnimation = () => {
    if (animation.type === 'goal' && animation?.option) {
      if (animation.option.startsWith('video-')) {
        const file = 'goal-' + animation.option.slice(6) + '.mp4';
        return <Video file={file} onFinish={() => endAnimation(animation)} />
      } else if (animation.option.startsWith('feature-')) {
        return <Feature isStarted={true} animation={animation} onFinish={() => endAnimation(animation)} />
      } else {
        return <Goal isStarted={true} animation={animation} onFinish={() => endAnimation(animation)} />
      }
    }
  }

  const endAnimation = () => {
    // unload animation
    console.log('unloading animation');
    setAnimation(false);
    // shift animation off the queue (after any exclusive duration)
    setAnimationQueue(animationQueue => animationQueue.slice(1));    
  }

  let containerClass = 'scoreboard-container';
  if (animation && animation?.option) {
    if (animation.option.startsWith('feature-')) {
      containerClass += ' animation-option-default';
    } else {
      containerClass += ' animation-option-' + animation.option;
    }
  }

  return (
    <Box className={containerClass} sx={containerStyle}>
      { !isEmbedded && <ScoreboardStyle /> }
      <Box sx={scoreboardStyle}>
        { animation && startAnimation() }
        <Box className="scoreboard-content">
          { scene === 'message' && message ? (
            <Grid container spacing={8}>
              <Grid xs={4}>
                <CurrentTime />
              </Grid>
              <Grid xs={8}>
                <MarkdownDisplay text={message.text} />
              </Grid>
            </Grid>
          ) : (scene === 'match' && match && (
            !match.isStarted ? (
              <Matchup match={match} />
            ) : (
              <>
                { !props?.isTimingHidden && (
                  <Stack direction="row" display="flex" alignItems="center" justifyContent="space-between" sx={rowStyle}>
                    <CurrentTime />
                    <Offline style={{ ml: '0rem' }} />
                    <Stack direction="row" display="flex" alignItems="center">
                      { !match.isFinished ? (
                        <>
                          <Typography sx={{ ml: '0rem', mr: '0.5rem' }}>{match.quarter}</Typography>
                          <ScoreboardTimer isLive={isLive} match={match} />
                        </>
                      ) : (
                        <Typography sx={{ fontWeight: 'bold' }}><SportsScoreRounded fontSize="sm" /></Typography>
                      )}
                    </Stack>
                  </Stack>
                )}
                <Grid container spacing={0} alignItems="center" sx={rowStyle}>
                  <Grid container justifyContent="center" xs={3} sx={{ p: '0 0.5rem' }}>
                    <TeamEmblem src={match?.homeClub?.imageUrl} team={match?.homeTeam} style={{ maxHeight: '5rem' }} />
                  </Grid>
                  <Grid xs={9}>
                    { props?.isJumbo && <Typography textAlign="center">{match.homeTeam}</Typography> }
                    <Scores matchId={match.id} team="home" goals={match.homeGoals} behinds={match.homeBehinds} />
                  </Grid>
                </Grid>
                <Grid container spacing={0} alignItems="center" sx={rowStyle}>
                  <Grid container justifyContent="center" xs={3} sx={{ p: '0 0.5rem' }}>
                    <TeamEmblem src={match?.awayClub?.imageUrl} team={match?.awayTeam} style={{ maxHeight: '5rem' }} />
                  </Grid>
                  <Grid xs={9}>
                    { props?.isJumbo && <Typography textAlign="center">{match.awayTeam}</Typography> }
                    <Scores matchId={match.id} team="away" goals={match.awayGoals} behinds={match.awayBehinds} />
                  </Grid>
                </Grid>
              </>
            )
          ))}
        </Box>
      </Box>
    </Box>
  );
};