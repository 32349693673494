import { Link as RouterLink } from 'react-router-dom';
import { Accordion, AccordionDetails, AccordionGroup, AccordionSummary, Button, Stack } from "@mui/joy";

import Members from '../club/Members';
import Scoreboards from '../club/Scoreboards';
import Messages from '../club/Messages';
import FixturesList from '../fixtures/FixturesList';
import Sheet from "../common/Sheet";
import { AddCircle } from '@mui/icons-material';

export const ClubAdmin = ({ club }) => {

  const clubId = club.id;

  return (
    <>
      <Stack direction="column" justifyContent="center">
        <Button variant="solid" color="primary" component={RouterLink} to={`/club/${clubId}/fixture`}>
          <AddCircle fontSize="sm" /> New Match
        </Button>
      </Stack>

      <Sheet>
        <AccordionGroup>
          <Accordion>
            <AccordionSummary>Approved users</AccordionSummary>
            <AccordionDetails>
              <Members clubId={clubId} />
            </AccordionDetails>
          </Accordion>
        </AccordionGroup>
      </Sheet>
      <Sheet>
        <AccordionGroup>
            <Accordion defaultExpanded>
              <AccordionSummary>Matches</AccordionSummary>
              <AccordionDetails>
                <FixturesList clubId={clubId} />
                <Stack direction="column" justifyContent="center" mt={2}>
                  <Button variant="solid" color="primary" component={RouterLink} to={`/club/${clubId}/fixtures`}>
                    All Matches
                  </Button>
                </Stack>
              </AccordionDetails>
            </Accordion>
        </AccordionGroup>
      </Sheet>
      <Sheet>
        <AccordionGroup>
            <Accordion>
              <AccordionSummary>Displays</AccordionSummary>
              <AccordionDetails>
                <Scoreboards clubId={clubId} />
              </AccordionDetails>
            </Accordion>
        </AccordionGroup>
      </Sheet>
      <Sheet>
        <AccordionGroup>
            <Accordion>
              <AccordionSummary>Messages</AccordionSummary>
              <AccordionDetails>
                <Messages clubId={clubId} />
              </AccordionDetails>
            </Accordion>
        </AccordionGroup>
      </Sheet>
    </>
  );
};