import React from 'react';
import { Avatar } from '@mui/joy';

export const TeamAvatar = ({ src = null, team = null, initials = null, size = 'md', sx = null }) => {
  return (
    <Avatar
      variant="soft"
      src={src}
      alt={team}
      size={size}
      sx={sx}
    >{initials}</Avatar>
  );
};
