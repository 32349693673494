import { Timestamp } from 'firebase/firestore';

export const getSecondsRemaining = (fixture) => {

  const intervalTimestamp = (fixture.timerIntervalAt instanceof Timestamp) ?
    fixture.timerIntervalAt.toDate() :
    fixture.timerIntervalAt;

  // timer data can be null if fixture hasn't loaded yet
  if (!fixture || !fixture.timerIntervalAt) return null;
  const now = Math.floor(Date.now() / 1000);
  const interval = Math.floor(intervalTimestamp / 1000);
  const secondsRemaining = Math.max(0, fixture.timerIntervalSeconds - (fixture.timerIsRunning ? (now - interval) : 0));
  // console.log('getSecondsRemaining: ', {secondsRemaining}, {now}, {interval}, {fixture});
  return secondsRemaining;
};