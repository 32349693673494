import { useState, useEffect, useCallback } from 'react';
import { getLocalStorage, setLocalStorage } from '../helpers/LocalStorage';

const useLocalStorage = (key, initialValue) => {
  // Custom hook to read value
  const readValue = useCallback(() => {
    return getLocalStorage(key, initialValue);
  }, [initialValue, key]);

  const [storedValue, setStoredValue] = useState(readValue);

  // wrap useState's setter function
  const setValue = (value) => {
    // allow value to be a function so we have the same API as useState
    const newValue = value instanceof Function ? value(storedValue) : value;

    setLocalStorage(key, newValue);

    setStoredValue(newValue);
  };

  // Read latest value from LocalStorage in case it changed
  useEffect(() => {
    setStoredValue(readValue());
  }, [key, readValue]);

  return [storedValue, setValue];
};

export default useLocalStorage;