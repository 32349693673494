export const getLocalStorage = (key, initialValue) => {
  if (typeof window === 'undefined') {
    return initialValue;
  }

  try {
    // retrieve from local storage
    const item = window.localStorage.getItem(key);
    return item ? JSON.parse(item) : initialValue;
  } catch (error) {
    console.warn(`Error reading localStorage key “${key}”:`, error);
    return initialValue;
  }
}

export const setLocalStorage = (key, value) => {

  // get existing value
  const storedValue = getLocalStorage(key, null);

  try {
    // allow value to be a function so we have the same API as useState
    const newValue = value instanceof Function ? value(storedValue) : value;

    // save to local storage
    window.localStorage.setItem(key, JSON.stringify(newValue));
  } catch (error) {
    console.warn(`Error setting localStorage key “${key}”:`, error);
  }
}
