import React from 'react';
import { useAuth } from '../hooks/useAuth';
import { Navigate } from 'react-router-dom';

const withAdminAuth = (WrappedComponent) => {
  return (props) => {
    const { user } = useAuth();

    return user?.isAdmin ? <WrappedComponent {...props} /> : <Navigate to="/forbidden" />;
  };
};

export default withAdminAuth;