import { Typography } from '@mui/joy';
import React, { useRef } from 'react';
import { CSSTransition, SwitchTransition } from 'react-transition-group';

import '../css/slide-up.css';

export const Score = ({ value, isStatic = false, style }) => {

  const nodeRef = useRef();

  const sx = {
    lineHeight: '1',
    ...style,
  }

  if (isStatic) return (
    <Typography fontWeight="bold" sx={sx}>{value}</Typography>
  );  

  return (
    <SwitchTransition mode="out-in">
      <CSSTransition nodeRef={nodeRef} key={value} classNames="slide-up-swap" timeout={300}>
        <Typography ref={nodeRef} fontWeight="bold" sx={sx}>{value}</Typography>
      </CSSTransition>
    </SwitchTransition>
  );
};
