import { Typography } from "@mui/joy";
import Link from "../components/common/Link";
import Sheet from "../common/Sheet";

const ClubPromo = () => {

  return (
    <Sheet title="Live the game">
      <Typography level="body-md">
        Bring your scoreboard and stats to life. Engage with your supporters at the game and online.
      </Typography>
      <Typography level="body-md">
        Email <Link to="mailto:mark@pointpost.app">mark@pointpost.app</Link> to
        get started.
      </Typography>
    </Sheet>
  );
}

export default ClubPromo;