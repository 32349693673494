import { useEffect, useState } from "react";
import { db } from "../firebaseConfig";
import { useAuth } from "../hooks/useAuth";
import { collection, doc, getDoc, getDocs, runTransaction } from "firebase/firestore";
import { Alert, Typography, Stack, Button, Skeleton, Avatar } from "@mui/joy";
import Sheet from "../common/Sheet";
import { TeamAvatar } from "../common/TeamAvatar";
import ClubSearch from "./ClubSearch";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import { Edit, Refresh } from "@mui/icons-material";
import { reload } from "../helpers/Window";
import Link from "../components/common/Link";

const ClubsList = () => {
  const [clubs, setClubs] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadError, setLoadError] = useState(null);
  const { user } = useAuth();

  useEffect(() => {

    if (!user) return;

    const fetchClubs = async () => {
      try {
        const clubsSnapshot = await getDocs(collection(db, `users/${user.uid}/clubs`));
    
        const clubPromises = clubsSnapshot.docs.map(async (clubDoc) => {
          const clubRef = doc(db, 'clubs', clubDoc.id);
          const docSnap = await getDoc(clubRef);
          return { ...docSnap.data(), id: docSnap.id };
        });
        
        const userClubs = await Promise.all(clubPromises);
        
        setClubs(userClubs);
        setLoading(false);
      } catch (error) {
        setLoadError(error.message);
        console.error('Error fetching clubs:', error);
      }
    };

    fetchClubs();

  }, [user]);

  const navigate = useNavigate();
  const handleJoin = async (selected) => {

    const member = {
      name: user.displayName,
      email: user.email,
      createdAt: new Date(),
    };

    try {
      await runTransaction(db, async (transaction) => {
        transaction.set(doc(db, `clubs/${selected.id}/members`, user.uid), member, { merge: true });
        transaction.set(doc(db, `users/${user.uid}/clubs`, selected.id), selected, { merge: true });

        navigate(`/club/${selected.id}`);
      });
      console.log('Transaction successfully committed!');
    } catch (error) {
      console.error('Transaction failed: ', error);
    }
  };

  if (loadError) {
    return <Alert variant="soft" color="danger">
      Could not load clubs. <Button variant="outlined" color="neutral" onClick={reload}>
        <Refresh /> Try again
      </Button>
    </Alert>;
  }

  const clubIds = clubs ? clubs.map(club => club.id) : [];

  return (
    <>
      <Sheet title="Clubs">
        { loading ? (
          <Stack direction="row" display="flex" alignItems="center" justifyContent="flex-start">
            <Avatar><Skeleton /></Avatar>
            <Typography sx={{ ml: '0.5rem' }}><Skeleton>Your club is loading...</Skeleton></Typography>
          </Stack>
        ) : (
          <>
            {clubs.map((club) => (
              <Stack key={club.id} direction="row" display="flex" alignItems="center" justifyContent="flex-start">
                <Link to={`/club/${club.id}`}>
                  <TeamAvatar team={club?.name} src={club?.imageUrl} />
                  <Typography sx={{ ml: '0.5rem' }}>{club.name}</Typography>
                </Link>
              </Stack>
            ))}
          </>
        )}
        <ClubSearch excludeIds={clubIds} isButton={true} buttonLabel="Join" handleButton={handleJoin} />
        { user?.isAdmin ? (
          <Button variant="solid" color="secondary" component={RouterLink} to="/club/new"><Edit fontSize="sm" /> Create a club</Button>
        ) : (
          <Typography level="body-md">
            Email <Link to="mailto:mark@pointpost.app">mark@pointpost.app</Link> to
            get your club up in here.
          </Typography>
        )}
      </Sheet>
    </>
  );
}

export default ClubsList;