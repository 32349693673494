import React, { createContext, useContext, useState } from 'react';

const FixtureContext = createContext();

export const useFixture = () => {
  const context = useContext(FixtureContext);
  if (!context) {
    throw new Error('useFixture must be used within a FixtureProvider');
  }
  return context;
};

export const FixtureProvider = ({ children }) => {
  const [fixture, setFixture] = useState(null);
  return (
    <FixtureContext.Provider value={{ fixture, setFixture }}>
      {children}
    </FixtureContext.Provider>
  );
};