import React from 'react';
import { useAuth } from '../hooks/useAuth';
import { getAuth } from 'firebase/auth';
import { signOut } from "firebase/auth";
import AppBar from '@mui/material/AppBar';
import { Avatar, Badge, Box, Dropdown, Menu, MenuButton, MenuItem, Typography } from '@mui/joy';
import { useNavigate } from 'react-router-dom';
import { Stack } from '@mui/material';
import useLocalStorage from '../hooks/useLocalStorage';
import Link from '../components/common/Link';

export const NavBar = ({ title = 'po1ntp0st' }) => {
  const [club, setCachedClub] = useLocalStorage('club', null);
  const auth = getAuth();
  const { user } = useAuth();
  const navigate = useNavigate();

  const handleSignOut = () => {
    signOut(auth).then(() => {
      setCachedClub(null);
      navigate('/');
    }).catch((error) => {
      console.error('Error signing out:', error);
    });
  };

  const barStyle = {
    bgcolor: 'primary.main',
    padding: '0rem 1rem',
  };

  const linkStyle = {
    color: 'inherit',
    lineHeight: 0,
    textDecoration: 'none',
    whiteSpace: 'nowrap',
  };

  const logoTextStyle = {
    m: '0',
    p: '0 0 0.1rem 0',
    display: 'inline-block',
    lineHeight: '1',
    fontSize: '1.5rem',
  };

  const logoImgStyle = {
    height: '2.5rem',
    paddingRight: '0.2rem',
  };

  return (
    <AppBar position="sticky" color="primary" enableColorOnDark>
      <Stack direction="row" spacing={1} sx={barStyle} alignItems="center" justifyContent="space-between">
        <Link to="/" sx={linkStyle}>
          <Stack direction="row" spacing={0} alignItems="center">
            <img src="/logo.svg" alt="logo" style={logoImgStyle} />
            <Typography level="h1" component="h1" sx={logoTextStyle}>
              {title}
            </Typography>
          </Stack>
        </Link>
        <Box sx={{ flexGrow: 1 }} />
        { club && (
          <Link to={`/club/${club.id}`} sx={linkStyle}>
            <Avatar src={club?.imageUrl} alt={club.name} sx={{ "--Avatar-size": "1.5rem" }} />
          </Link>
        )}
        { user && (
          <Dropdown>
            <MenuButton variant="plain" sx={{
              p: 0,
              ':hover': {
                backgroundColor: 'transparent',
                color: 'inherit',
              },
              ':active': {
                backgroundColor: 'transparent',
               
              }
            }}>
              <Badge
                variant="plain"
                size="sm"
                color={user?.isAdmin ? "warning" : "default"}
                invisible={!user?.isAdmin}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                badgeInset="14%"
              >
                <Avatar src={user?.photoURL} alt={user?.displayName} sx={{ "--Avatar-size": "1.5rem" }} />
              </Badge>
            </MenuButton>
            <Menu>
              <MenuItem color="danger" onClick={handleSignOut}>Sign out</MenuItem>
            </Menu>
          </Dropdown>          
        )}
      </Stack>
    </AppBar>
  )
}
