import React, { useEffect, useState } from "react";
import { collection, getDocs, limit, orderBy, query } from "firebase/firestore";
import { db } from '../firebaseConfig';

import { Grid, Button } from "@mui/joy";
import { dateTimeString, formatDateRelative } from "../helpers/DateTime";
import { MessageModal } from "../messages/MessageModal";
import { ScoreboardButton } from "../match/ScoreboardButton";
import { Edit } from "@mui/icons-material";

const Messages = ({ clubId }) => {
  const [messages, setMessages] = useState([]);
  const [editMessage, setEditMessage] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const fetchMessages = async () => {
      const messagesQuery = query(
        collection(db, `clubs/${clubId}/messages`),
        orderBy('createdAt', 'desc'),
        limit(20)
      );
      const messageSnapshot = await getDocs(messagesQuery);
      const messageList = messageSnapshot.docs.map(doc => ({
        ...doc.data(),
        id: doc.id,
        messageId: doc.id,
        clubId: clubId,
        createdAt: dateTimeString(doc.data().createdAt),
      }));
      console.log({messageList});
      setMessages(messageList);
    };

    fetchMessages();
  }, [clubId, isModalOpen]);

  const handleEdit = (message) => {
    setEditMessage(message);
    setIsModalOpen(true);
  }

  const handleClose = () => {
    setIsModalOpen(false);
  }

  return (
    <>
      {editMessage && (
        <MessageModal isOpen={isModalOpen} clubId={clubId} message={editMessage} handleClose={handleClose} />
      )}
      <Grid 
        container 
        columns={{ xs: 2, md: 4 }}
        spacing={{ xs: 2 }}
        sx={{ flexGrow: 1 }}
      >
        {messages.map((message, index) => {
          const ScoreboardButtonWithMessage = () => <ScoreboardButton clubId={clubId} message={message} />;

          return (
            <Grid xs={2} key={index}>
              <div><strong>{message.text}</strong></div>
              <div><em>added {formatDateRelative(message.createdAt, {isTodayExplicit: true, isMidSentence: true})}</em></div>
              <div>
                <Button variant="plain" size="md" color="primary" sx={{ p: '0 0.5rem', lineHeight: '1rem' }} onClick={ () => handleEdit(message) }>
                  <Edit fontSize="xl" />
                </Button>
                <ScoreboardButtonWithMessage />
              </div>
            </Grid>
          )
        })}
      </Grid>
    </>
  );
}

export default Messages;