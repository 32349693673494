import React, { useEffect, useState } from 'react';
import { db } from '../../firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';
import { Stack, Button } from '@mui/joy';
import { reload } from '../../helpers/Window';
import { Refresh } from '@mui/icons-material';

const VersionCheck = () => {
  const [isOutdated, setIsOutdated] = useState(false);

  useEffect(() => {
    const checkVersion = async () => {
      const metaTag = document.querySelector('meta[name="build-timestamp"]');
      const clientTimestamp = metaTag ? metaTag.getAttribute('content') : null;

      try {
        const configDoc = await getDoc(doc(db, 'config', 'production'));
        if (configDoc.exists()) {
          const versionPrompt = configDoc.data()?.version?.prompt;

          if (clientTimestamp && versionPrompt) {
            const serverTimestamp = Math.round(versionPrompt.toMillis() / 1000);

            console.log('%cClient version timestamp:', 'color: pink;', clientTimestamp);
            console.log('%cServer version timestamp:', 'color: pink;', serverTimestamp);

            const placeholder = "%BUILD_TIMESTAMP%";

            if (
              clientTimestamp && serverTimestamp &&
              clientTimestamp !== placeholder && (
                clientTimestamp < serverTimestamp
              )
            ) {
              setIsOutdated(true);
            }
          }
        } else {
          console.error('No version document found!');
        }
      } catch (error) {
        console.error('Error fetching server version:', error);
      }
    };

    checkVersion();
  }, []);

  if (!isOutdated) return;

  return (
    <Stack direction="row" display="flex" justifyContent="center" my="0.5rem">
      <Button variant="outlined" color="warning" onClick={reload}>
        <Refresh /> App update available
      </Button>
    </Stack>
  );
};

export default VersionCheck;