import { useState } from "react";
import { Stack, FormControl, FormHelperText, FormLabel, Input, Switch, Select, Option } from "@mui/joy";

export const QuarterForm = ({ event, handleChange }) => {

  const [values, setValues] = useState({
    quarter: event.quarter,
    quarterLength: event.quarterLength,
    type: event.isMatchFinished ? 'finish' : 'quarter',
    isMatchFinished: event.isMatchFinished ?? false,
    result: event.result ?? false,
  });

  const handleFinishSwitch = (e) => {
    const isMatchFinished = e.target.checked ? true : false;
    setValues({
      ...values,
      isMatchFinished: isMatchFinished,
    });
    handleChange('isMatchFinished', isMatchFinished);
    handleChange('type', isMatchFinished ? 'finish' : 'quarter');
    handleChange('label', isMatchFinished ? 'Match finish' : 'Start');
    handleChange('result', isMatchFinished ? values.result : null);
    handleChange('quarter', isMatchFinished ? null : values.quarter);
  }

  const handleSelectChange = (name, e, newValue) => {
    setValues({
      ...values,
      [name]: newValue
    });
    handleChange(name, newValue);
  };

  const handleFieldChange = (e) => {
    console.log('handleFieldChange', e.target.name, e.target.value);
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
    handleChange(e.target.name, e.target.value);
  };

  const controlStyle = { mt: '0.5rem' };

  return (
    <>
      { event.type !== 'start' && (
        <FormControl orientation="horizontal" sx={controlStyle}>
          <Stack direction="row" width="100%" justifyContent="center">
            <FormLabel htmlFor="isMatchFinished" sx={{ mr: '0.5rem', fontSize: '0.8rem' }}>New Quarter</FormLabel>
            <Switch variant="solid" name="isMatchFinished" label="Match finished" checked={values.isMatchFinished} onChange={handleFinishSwitch} />
            <FormLabel htmlFor="isMatchFinished" sx={{ m: '0 0 0 0.5rem', fontSize: '0.8rem' }}>End Match</FormLabel>
          </Stack>
        </FormControl>
      )}
      { values.isMatchFinished ? (
        <Select
          name="result"
          value={values.result}
          placeholder="Match result"
          required
          defaultValue={values.result}
          sx={{ mt: '1rem' }}
          onChange={(e, newValue) => handleSelectChange('result', e, newValue)}>
          <Option value="home win">Home win (by score)</Option>
          <Option value="away win">Away win (by score)</Option>
          <Option value="draw">Draw</Option>
          <Option value="home win (forfeit)">Home win (by forfeit)</Option>
          <Option value="away win (forfeit)">Away win (by forfeit)</Option>
          <Option value="abandoned">Match abandoned</Option>
          <Option value="postponed">Match postponed</Option>
        </Select>
      ) : (
        <>
          <FormControl orientation="horizontal" sx={controlStyle}>
            <Stack direction="row" width="100%" justifyContent="center">
              <FormLabel htmlFor="quarter">Quarter</FormLabel>
              <Input
                name="quarter"
                label="Quarter"
                type="text"
                disabled={values.isMatchFinished}
                value={values.quarter}
                onChange={(e) => handleFieldChange(e)}
                size="xs"
                sx={{ width: '3rem', '& input': { textAlign: 'center' } }}
              />
            </Stack>
          </FormControl>
          <FormControl orientation="horizontal" sx={controlStyle}>
            <Stack direction="row" width="100%" justifyContent="center">
              <FormLabel htmlFor="quarterLength">Length</FormLabel>
              <Input
                name="quarterLength"
                label="Length"
                type="number"
                disabled={values.isMatchFinished}
                value={values.quarterLength}
                onChange={(e) => handleFieldChange(e)}
                size="xs"
                sx={{ width: '3rem', '& input': { textAlign: 'center' } }}
              />
              <FormHelperText sx={{ m: '0 0 0 0.5rem' }}>minutes</FormHelperText>
            </Stack>
          </FormControl>
        </>
      )}
    </>
  );
}