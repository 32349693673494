import React, { useEffect, useState } from 'react';
import { useFixture } from './FixtureContext';
import './Goal.css';
import './Feature.css';
import { Box, Typography } from '@mui/joy';

export const Feature = ({ isStarted, animation, onFinish }) => {
  const [isPreloading, setIsPreloading] = useState(true);
  const [isFinished, setIsFinished] = useState(false);
  const { fixture } = useFixture();

  console.log('feature animation fixture', { fixture });

  const imgSrc = '/features/' + animation.option + '.jpg';
  const text = animation?.props?.text;
  const sx = animation?.props?.sx;

  useEffect(() => {
    if (!isStarted) return;

    const prepTimer = setTimeout(() => {
      console.log('feature animation preloaded...');
      setIsPreloading(false);

        const mainTimer = setTimeout(() => {
          console.log('feature animation finishing...');
          setIsFinished(true);
          onFinish();
        }, 9500);
    
        return () => clearTimeout(mainTimer);
      }, 5000);

    return () => clearTimeout(prepTimer);
  }, [isStarted, onFinish]);

  if (!isStarted || isFinished) return;

  if (isPreloading) {
    return (
      <Box className="feature-image-preloader" sx={{ backgroundImage: `url(${imgSrc})` }} />
    );
  }

  console.log('feature animation starting...', {animation});

  return (
    <div className="goal-container">
      <Box className="goal-feature-image" sx={{ backgroundImage: `url(${imgSrc})` }} />
      { text && (<Typography level="h1" className="goal-feature-text" sx={sx}>{ text }</Typography>)}
    </div>
  );
};
