import React from "react";
import { Grid, Typography } from "@mui/joy";
import { Score } from "../common/Score";
import { EmojiEvents as WinnerIcon } from "@mui/icons-material";

export const Result = ({ fixture }) => {

  const resultText = (result) => {
    switch (result) {
      case 'home win':
        return 'defeated';
      case 'away win':
        return 'defeated by';
      case 'draw':
        return 'drew with';
      default:
        return 'vs';
    }
  };

  const team = (who) => {
    return (
      <>
        <Grid xs={7}>
          <Typography>{fixture[who + 'Team']}</Typography>
        </Grid>
        <Grid xs={1}>
          { fixture.result?.startsWith(who + ' win') && <WinnerIcon sx={{ fontSize: '0.8rem', mt: '0.35rem' }} /> }
        </Grid>
        <Grid xs={2} sx={{ textAlign: 'right', }}>
          <Score value={fixture[who + 'Goals']} isStatic style={scoresStyle} />
          .
          <Score value={fixture[who + 'Behinds']} isStatic style={scoresStyle} />
        </Grid>
        <Grid xs={2}><Score value={fixture[who + 'Score']} isStatic style={totalStyle} /></Grid>
      </>
    )
  };

  const scoresStyle = {
    display: 'inline',
    fontSize: '0.8rem',
    fontWeight: 'normal',
  };

  const totalStyle = {
    lineHeight: '1.5rem',
    fontSize: '1.0rem',
    fontWeight: 'bold',
  };

  return (
    <>
      {team('home')}
      <Grid xs={7}><Typography fontSize="xs">{resultText(fixture.result)}</Typography></Grid>
      <Grid xs={5}></Grid>
      {team('away')}
    </>
  );
};
