import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Modal, ModalDialog, Stack, Typography } from "@mui/joy";
import { ModalClose } from "../common/ModalClose";
import { addDoc, collection, deleteDoc, doc, runTransaction, serverTimestamp, updateDoc } from "firebase/firestore";
import { db } from "../firebaseConfig";
import { Close, Delete, Done, Timer as TimerIcon } from "@mui/icons-material";
import { QuarterForm } from "./QuarterForm";
import { TeamAvatar } from "../common/TeamAvatar";
import Timer from "../common/Timer";
import { TimerForm } from "./TimerForm";
import { GoalForm } from "./GoalForm";

export const EventModal = ({ isOpen, handleClose, eventStub, action, fixture, setFixture, setIsEventLoading }) => {
  const [open, setOpen] = useState(false);
  const [eventData, setEventData] = useState(eventStub);

  const { clubId, fixtureId } = useParams();
  const fixturePath = `clubs/${clubId}/fixtures/${fixtureId}`;

  useEffect(() => {
    if (isOpen !== undefined) setOpen(isOpen);
    if (eventStub !== undefined) setEventData(eventStub);
  }, [isOpen, eventStub]);

  if (!isOpen) return;

  const createEvent = async (event) => {
    console.log('creating event:', event);

    setIsEventLoading(true);

    // extract event data from the id, if it exists
    const { id, ...eventData } = event;

    console.log({id});
    console.log({eventData});

    const isApplied = action !== 'pending';

    try {
      // capture local timestamp for immediate display
      const localTimestamp = Date.now();

      // create event
      const eventDataComplete = {
        quarter: fixture.quarter || 'Q1',
        [isApplied ? 'appliedAt' : 'pendingAt']: serverTimestamp(),
        createdAt: serverTimestamp(),
        timerIsRunning: fixture.timerIsRunning ?? true,
        timerIntervalAt: fixture.timerIntervalAt ?? serverTimestamp(),
        timerIntervalSeconds: fixture.timerIntervalSeconds ?? fixture.quarterLength * 60,
        ...eventData,
      };
      await addDoc(collection(db, fixturePath, isApplied ? 'events' : 'eventsPending'), eventDataComplete);
      console.log('event created!', eventDataComplete);
      
      if (isApplied) {
        // apply the changes to the match

        let matchData = {};

        switch (event.type) {
          case 'start':
            matchData = {
              isStarted: true,
              quarter: event.quarter,
              quarterLength: event.quarterLength,
              homeGoals: 0,
              homeBehinds: 0,
              homeScore: 0,
              awayGoals: 0,
              awayBehinds: 0,
              awayScore: 0,
              timerIsRunning: false,
              timerIsCountdown: true,
              timerIntervalAt: serverTimestamp(),
              timerIntervalSeconds: event.quarterLength * 60,
            };      
            break;
          case 'quarter':
            matchData = {
              quarter: event.quarter,
              quarterLength: event.quarterLength,
              timerIsRunning: false,
              timerIntervalAt: serverTimestamp(), // start the timer!
              timerIntervalSeconds: event.quarterLength * 60,
              isFinished: null,
              result: null,
            }
            break;
          case 'finish':
            matchData = {
              isFinished: true,
              result: event.result,
              timerIsRunning: false,
              timerIntervalAt: serverTimestamp(),
              timerIntervalSeconds: event.secondsRemaining, // typically zero...
            }
            break;
          case 'goal':
          case 'behind':
            const key = event.team + event.label + 's';
            const scoreKey = event.team + 'Score';
            matchData = {
              [key]: fixture[key] + 1,
              [scoreKey]: fixture[scoreKey] + (event.label === 'Goal' ? 6 : 1),
            }
            break;
          case 'pause':
            matchData = {
              timerIsRunning: false,
              timerIntervalAt: serverTimestamp(),
              timerIntervalSeconds: event.secondsRemaining,
            };
            break;
          case 'resume':
            // move the timer interval, retain the seconds remaining from the pause
            matchData = {
              timerIsRunning: true,
              timerIntervalAt: serverTimestamp(),
            };
            break;
          case 'timer':
            matchData = {
              timerIntervalAt: serverTimestamp(),
              timerIntervalSeconds: event.secondsRemaining,
            }
            break;
          default:
            // TODO handle unknown events
            matchData = {};
        }

        // handle animation
        if (event.isAnimated && event.animation) {
          const animation = {
            key: (Math.random() + 1).toString(36).substring(2, 15),
            ...event.animation,
          }
          matchData = {
            ...matchData,
            animation: animation,
          }
        }

        console.log({fixture, matchData});

        try {
          await runTransaction(db, async (transaction) => {
            transaction.update(doc(db, fixturePath), {
              ...matchData,
              updatedAt: serverTimestamp(),
            }, { merge: true });
            console.log('fixture updated!');
            if (fixture?.broadcastRef) {
              transaction.update(fixture.broadcastRef, {
                fixture: { ...fixture, ...matchData },
                [`fixture.updatedAt`]: serverTimestamp(),
              }, { merge: true });
              console.log('broadcast updated!');
            }
          });
        } catch (error) {
          console.error('Error updating document: ', error);
        }

        if (id) {
          try {
            // delete pending event
            await deleteDoc(doc(db, fixturePath, 'eventsPending', id));
          } catch (error) {
            console.error('Error deleting document: ', error);
          }
        }

        setFixture(prevFixture => ({
          ...matchData,
          ...prevFixture,
          timerIntervalAt: localTimestamp, // local timestamp for immediate display
        }));
      }
    } catch (error) {
      console.error('Error creating event: ', error);
    } finally {
      setIsEventLoading(false);
    }
  }

  const deleteEvent = async (event) => {
    console.log('deleting event:', event);

    const isApplied = (event.isApplied || event.appliedAt);

    const eventCollection = isApplied ? 'events' : 'eventsPending';

    // extract event data without the now-defunct id
    const { id, ...eventData } = event;

    try {
      // move the event to trash
      await addDoc(collection(db, fixturePath, 'eventsDeleted'),
      {
        ...eventData,
        deletedAt: serverTimestamp(),
      });
      console.log('event backup succeeded');

      try {
        // delete event
        await deleteDoc(doc(db, fixturePath, eventCollection, event.id));
        console.log('event deleted (from ' + eventCollection + ')');

        if (isApplied) {
          // revert changes to the match
          let matchData = {};

          switch (event.type) {
            case 'goal':
            case 'behind':
              // revert a score
              const key = event.team + event.label + 's';
              const scoreKey = event.team + 'Score';
              matchData = {
                [key]: fixture[key] - 1,
                [scoreKey]: fixture[scoreKey] - (event.label === 'Goal' ? 6 : 1),
              }
              break;
            case 'pause':
            case 'unpause':
              // TODO is reverting pause/unpause even possible and/or wise?
              break;
            default:
              // TODO handle other events
              matchData = {};
          }

          console.log({matchData});

          try {
            // update match
            await updateDoc(doc(db, fixturePath), {
              ...matchData,
              updatedAt: serverTimestamp(),
            });
            console.log('fixture updated (revert)!');
          } catch (error) {
            console.error('Error updating document: ', error);
          }

        }
      } catch (error) {
        console.error('Error deleting document: ', error);
      }
    } catch (error) {
      console.error('Error backing up event: ', error);
    }
  }

  const handleTimerChange = (value) => {
    setEventData(prevEventData => ({
      ...prevEventData,
      secondsRemaining: Math.max(prevEventData.secondsRemaining + value, 0),
    }));
  }

  const handleFieldChange = (name, value) => {
    console.log('Event modal field changed', {name, value});
    setEventData(prevEventData => ({
      ...prevEventData,
      [name]: value,
    }));
  }

  const handleApply = async () => {
    createEvent(eventData);
    handleClose();
  };

  const handleDelete = async () => {
    deleteEvent(eventData);
    handleClose();
  };

  const handleCancel = () => {
    handleClose();
  }
  
  const renderModalContent = () => {
    // default values
    let { title, showTimer, description, applyLabel, cancelLabel, renderEmblem, renderForm } = {
      title: 'Confirm',
      showTimer: action !== 'delete',
      description: '',
      applyLabel: 'OK',
      cancelLabel: 'Cancel',      
    }

    console.log({eventStub, action});

    switch (eventStub.type) {
      case 'start':
      case 'quarter':
      case 'finish':
        title = eventStub.type === 'start'
          ? 'Match setup'
          : eventStub.type === 'quarter'
            ? 'End of quarter'
            : 'End of match';
        showTimer = false;
        renderForm = () => <QuarterForm event={eventStub} handleChange={handleFieldChange} />;
        break;
      case 'goal':
      case 'behind':
        renderEmblem = () => (
          <TeamAvatar
            src={fixture?.[eventStub.team + 'Club']?.imageUrl}
            team={fixture?.[eventStub.team + 'Team']}
            sx={{ m: '0 0.5rem 0 0' }} />
        );
        if (action === 'delete') {
          title = eventStub.label;
          break;
        }
        title = eventStub.label;
        if (eventStub.type === 'goal') {
          renderForm = () => <GoalForm event={eventStub} handleChange={handleFieldChange} />;
        }
        break;
      case 'pause':
        title = 'Pause';
        break;
      case 'resume':
        title = 'Start';
        break;
      case 'timer':
        title = 'Timer';
        applyLabel = 'Set';
        renderForm = () => <TimerForm handleTimerChange={handleTimerChange} />;
        break;
      default:
        // TODO handle unknown events
        console.error('Unknown event', {eventStub});
    }

    return {
      modalContent: { title, showTimer, description, applyLabel, cancelLabel },
      renderEmblem,
      renderForm,
    };
  }

  const { modalContent, renderEmblem, renderForm } = renderModalContent();

  const secondsOn = eventData?.secondsRemaining ?? eventStub.secondsRemaining;

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}>
        <ModalDialog
          aria-labelledby={modalContent.title}
          aria-describedby={modalContent.description}>
          <ModalClose />
          <Stack direction="row" display="flex" justifyContent="center">
            { renderEmblem && renderEmblem() }
            <Typography level="h4" component="h2" fontWeight="bold">{modalContent.title}</Typography>
          </Stack>
          { modalContent.showTimer && (
            <Stack direction="row" justifyContent="center">
              <TimerIcon fontSize="sm" sx={{ m: '0.25rem 0.2rem 0 0' }} />
              <Timer secondsOn={secondsOn} fontSize="md" />
            </Stack>
          )}
          { modalContent.description && (
            <Typography level="body2" component="p">{modalContent.description}</Typography>
          )}
          { renderForm && renderForm() }
          <Stack direction="row" justifyContent="center" spacing={2} sx={{ mt: '1rem' }}>
            { action === 'delete' ? (
              <Button variant="solid" size="sm" onClick={handleDelete}>
                <Delete fontSize="sm" sx={{ mr: '0.2rem' }} />Delete
              </Button>
              ) : (
              <Button variant="solid" size="sm" onClick={handleApply}>
                <Done fontSize="sm" sx={{ mr: '0.2rem' }} />{modalContent.applyLabel}
              </Button>
            )}
            <Button variant="plain" size="sm" onClick={handleCancel}>
              <Close fontSize="sm" sx={{ mr: '0.2rem' }} />{modalContent.cancelLabel}
            </Button>
          </Stack>
        </ModalDialog>
      </Modal>
    </>
  );
};
