import { Alert } from "@mui/joy";

export const ClubFan = ({ club }) => {

  return (
    <>
      <Alert variant="soft" color="danger">
        No access to this club. Awaiting approval from an admin.
      </Alert>
    </>
  );
};