import React from 'react';
import './Video.css';

const Video = ({ file, onFinish }) => {
  return (
    <video
      className="video-full-width-height"
      src={`/videos/${file}`}
      autoPlay
      muted
      onEnded={onFinish}
    ></video>
  );
};

export default Video;