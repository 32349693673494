import { useState } from "react";
import { Stack, FormControl, FormLabel, Switch, Select, Option } from "@mui/joy";
import { Animation } from "@mui/icons-material";

export const GoalForm = ({ event, handleChange, optionDefault = 'fade-zoom', isProtected = true }) => {
  const [isAnimated, setIsAnimated] = useState(!isProtected);
  const [animationOption, setAnimationOption] = useState(optionDefault);
  const [animationProps, setAnimationProps] = useState(null);

  const options = [
    { value: 'fade-zoom', label: 'Fade + Zoom' },
    { value: 'swing', label: 'Swing' },
    { value: 'spin', label: 'Dizzy' },
    { 
      value: 'feature-pointpost-lslc',
      label: 'pointpost.app',
    },
    { 
      value: 'feature-nffc-ari',
      label: 'Feature: Ari',
      text: "ari",
    },
    { 
      value: 'feature-nffc-caido',
      label: 'Feature: Caido',
      text: "caido",
      sx: { fontSize: '30%' },
    },
    { 
      value: 'feature-nffc-cooka',
      label: 'Feature: Andre Cook',
      text: "cooka",
      sx: { fontSize: '30%' },
    },
    { 
      value: 'feature-nffc-jimmy',
      label: 'Feature: James Rees',
      text: "jim",
    },
    { 
      value: 'feature-nffc-john',
      label: 'Feature: John Ahern',
      text: "john",
    },
    { 
      value: 'feature-nffc-john2',
      label: 'Feature: John Ahern 2',
      text: "john",
    },
    { 
      value: 'feature-nffc-maz3',
      label: 'Feature: Maz',
      text: "maz",
    },
    { 
      value: 'feature-nffc-josh',
      label: 'Feature: Josh Miller',
      text: "josh",
    },
    { 
      value: 'feature-nffc-chris',
      label: 'Feature: Chris Miller',
      text: "chris",
    },
    {
      value: 'feature-nffc-prez',
      label: 'Feature: Prez',
      text: "prez",
    },
    {
      value: 'feature-nffc-sando',
      label: 'Feature: Sando',
      text: "sando",
      sx: { fontSize: '30%' },
    },
    {
      value: 'feature-nffc-shez',
      label: 'Feature: Sherrin',
      text: "shez",
    },
    {
      value: 'feature-nffc-snake',
      label: 'Feature: Snake',
      text: "snake",
      sx: { fontSize: '30%' },
    },
    {
      value: 'feature-nffc-thooms',
      label: 'Feature: Thoomey',
      text: "thooms",
      sx: { fontSize: '30%' },
    },
    { value: 'video-anvil', label: 'Anvil Video' },
    { value: 'video-fire', label: 'Fire Video' },
    { value: 'video-nffc-nic', label: "Feature: Nick D'Andrea" },
  ];
  
  const [values, setValues] = useState({
    isAnimated: event.isAnimated ?? false,
    animation: event.animation ?? {
      type: 'goal',
      team: event.team,
      option: 'default',
    },
  });

  const updateAnimation = (option, props) => {
    const animation = {
      type: 'goal',
      team: event.team,
      option,
      props,
      duration: 5000,
    }
    setValues({
      ...values,
      animation: animation,
    });
    handleChange('animation', animation);
  }

  console.log('GoalForm', {event}, {values});

  const handleSwitch = (e) => {
    const isAnimated = e.target.checked ? true : false;
    setIsAnimated(isAnimated);
    setValues({
      ...values,
      isAnimated: isAnimated,
    });
    handleChange('isAnimated', isAnimated);
    if (isAnimated) {
      updateAnimation(animationOption, animationProps);
    } else {
      handleChange('animation', null);
    }
  }

  const findAnimationProps = (selectedOption) => {
    const animationProps = options.find(option => option.value === selectedOption);
    return animationProps;    
  }

  const handleAnimationOptionChange = (e, newValue) => {
    
    setAnimationOption(newValue);
    
    if (!newValue) return;

    const newProps = findAnimationProps(newValue);
    setAnimationProps(newProps);
    updateAnimation(newValue, newProps);
  };

  const controlStyle = { mt: '0.5rem' };

  return (
    <>
      { isProtected && (
        <FormControl orientation="horizontal" sx={controlStyle}>
          <Stack direction="row" width="100%" justifyContent="center">
            <FormLabel htmlFor="isAnimated" sx={{ mr: '0.5rem', fontSize: '0.8rem' }}>Animation</FormLabel>
            <Switch variant="solid" name="isAnimated" label="Match finished" checked={isAnimated} onChange={handleSwitch} />
            <FormLabel htmlFor="isAnimated" sx={{ ml: '0.5rem', fontSize: '0.8rem' }}><Animation sx={{ fontSize: '1.1rem' }} /></FormLabel>
          </Stack>
        </FormControl>
      )}
      { isAnimated && (
        <Select
          name="animationOption"
          value={animationOption}
          placeholder="Animation"
          required
          defaultValue="default"
          sx={{ mt: '1rem' }}
          onChange={handleAnimationOptionChange}
        >
          {options.map((option, index) => (
            <Option key={index} value={option.value}>
              {option.label}
            </Option>
          ))}
        </Select>
      )}
    </>
  );
}