import React, { useState } from 'react';
import { useAuth } from './hooks/useAuth';
import SignIn from './SignIn';
import ClubsList from './clubs/ClubsList';
import ClubPromo from './clubs/ClubPromo';
import ClubC2A from './components/club/ClubC2A';
import BroadcastList from './components/fan/BroadcastList';
import CircularProgress from './components/common/CircularProgress';
import VersionCheck from './components/utils/VersionCheck';

export const Home = () => {
  const [isLookingToScore, setIsLookingToScore] = useState(false);
  const { user, isAuthLoading } = useAuth();

  const isSignedIn = !!user;

  const handleClubC2AClick = () => {
    setIsLookingToScore(true);
  };

  return (
    <>
      <VersionCheck />
      { isSignedIn ? (
        <>
          { isLookingToScore ? (
            <ClubsList />
          ) : (
            <ClubC2A onClick={handleClubC2AClick} />
          )}
          <BroadcastList />
        </>
      ) : isAuthLoading ? (
        <CircularProgress />
      ) : (
        <>
          { !isAuthLoading && <SignIn />}
          <ClubPromo />
        </>
      )}
    </>
  );
};
