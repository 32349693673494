import React, { useEffect, useState } from 'react';
import { useAuth } from '../../hooks/useAuth';
import { db } from '../../firebaseConfig';
import { collection, doc, limit, onSnapshot, orderBy, query, runTransaction, serverTimestamp } from 'firebase/firestore';
import { Avatar, Button, Stack, Textarea, Typography } from "@mui/joy";
import { Send } from '@mui/icons-material';
import { Comment } from './Comment';

export const CommentList = ({ broadcast }) => {

  const { user } = useAuth();
  const [comments, setComments] = useState([]);
  const [commentDraft, setCommentDraft] = useState('');
  
  const broadcastId = broadcast?.id;

  // subscribe to comments
  useEffect(() => {
    const commentsQuery = query(
      collection(db, `broadcasts/${broadcastId}/comments`),
      orderBy('createdAt', 'desc'),
      limit(50)
    );

    const unsubscribe = onSnapshot(commentsQuery, (docSnap) => {
      const updatedData = docSnap.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      console.log('comments: ', updatedData);

      setComments(updatedData);
    });

    return () => unsubscribe();
  }, [broadcastId]);

  const changeComment = (e) => {
    const text = e.target.value.slice(0, 150);
    setCommentDraft(text);
  }

  const handlePointPost = async () => {
    console.log('commentDraft: ', commentDraft);
    if (commentDraft.length === 0) return;

    // create comment
    const newComment = {
      user: {
        uid: user?.uid,
        displayName: user?.displayName,
        photoURL: user?.photoURL,
      },
      message: commentDraft,
    };

    // optimistically add comment to UI
    setComments(prevComments => [{
      ...newComment,
      createdAt: new Date().toISOString(),
    }, ...prevComments]);

    try {
      const broadcastCommentRef = doc(collection(db, `broadcasts/${broadcastId}/comments`));
      const userCommentRef = doc(collection(db, `users/${user.uid}/comments`), broadcastCommentRef.id);

      await runTransaction(db, async (transaction) => {
        // update broadcast
        transaction.update(doc(db, `broadcasts/${broadcastId}`), {
          commentCount: (broadcast?.commentCount || 0) + 1,
          updatedAt: serverTimestamp(),
        });
        console.log("broadcast updated!");

        // add comment to broadcast
        transaction.set(broadcastCommentRef, {
          ...newComment,
          createdAt: serverTimestamp(),
        });
        console.log("comment added to broadcast's collection!");

        // add comment to user
        transaction.set(userCommentRef, {
          ...newComment,
          broadcastId,
          createdAt: serverTimestamp(),
        });
        console.log("comment added to user's collection!");
      });
    } catch (error) {
      console.error("Error adding comment: ", error);
    }

    // clear the draft
    setCommentDraft('');
  }

  const handleCommentAction = async (commentId, action) => {
    // TODO
  }

  return (
    <>
      <Stack direction="row" display="flex" justifyContent="space-between" sx={{ mb: "0.5rem" }}>
        <Textarea
          name="commentDraft"
          value={commentDraft}
          onChange={changeComment}
          placeholder="Post your point..."
          minRows={2}
          size="sm"
          startDecorator={
            <Stack
              direction="row"
              display="flex"
              alignItems="center"
              sx={{
                gap: 'var(--Textarea-paddingBlock)',
                pb: 'var(--Textarea-paddingBlock)',
                pl: 'var(--Textarea-paddingBlock)',
                borderBottom: '1px solid',
                borderColor: 'divider',
                flex: 'auto',
              }}
            >
              <Avatar variant="solid" size="xs" src={user?.photoURL} alt={user?.displayName} />
              <Typography level="body-xs" sx={{ ml: "0.2rem" }}>{user?.displayName}</Typography>
              <Typography level="body-xs" sx={{ ml: "auto" }}>
                {commentDraft.length} / 150
              </Typography>
              <Button
                variant="plain"
                size="sm"
                sx={{ p: "0.5rem" }}
                onClick={handlePointPost}
              ><Send fontSize="xs" /></Button>
            </Stack>}
          sx={{ flexGrow: 1, width: '100%' }}
        />
      </Stack>
      {comments.length ? comments.map((comment, index) => (
        <Comment key={index} comment={comment} handleAction={handleCommentAction} />
      )) : (
        <Typography fontSize="sm" textAlign="center" fontStyle="italic">All's quiet in here 💤</Typography>
      )}
    </>
  );
}