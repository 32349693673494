import React from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import App from './App';
import { Club } from './club/Club';
import { Fixtures } from './fixtures/Fixtures';
import FixtureForm from './fixtures/FixtureForm';
import { ClubForm } from './club/ClubForm';
import { Match } from './match/Match';
import { FixtureProvider } from './scoreboard/FixtureContext';
import { Scoreboard } from './scoreboard/Scoreboard';
import { ScoreboardOnDemand } from './scoreboard/ScoreboardOnDemand';
import { Wrap } from './common/Wrap';
import { Home } from './Home';
import { TestGrid } from './test/TestGrid';
import Forbidden from './common/Forbidden';
import { RootBoundary } from './RouteBoundary';
import clubLoader from './loaders/loadClub';
import { ProtectedRoute } from './components/auth/ProtectedRoute';
import { Broadcast } from './components/fan/Broadcast';
import { Legal } from './components/legal/Legal';
import SignIn from './SignIn';
import { TestSound } from './components/test/TestSound';

const Routes = () => {
  /**
   * Routes
   * 1. add a child here as appropriate
   * 2. (optional) add route in worker.js for caching
   */
  const router = createBrowserRouter([
    {
      path: "/",
      element: <App />,
      errorElement: <RootBoundary />,
      // loader: rootLoader,
      // action: rootAction,
      children: [
        {
          element: <Wrap />,
          children: [
            {
              index: true,
              element: <Home />,
              // loader: fixtureLoader,
            },
            {
              path: "/legal",
              element: <Legal />,
            },
            {
              path: "/business",
              element: <Legal />,
            },
            {
              path: "/fb",
              element: <SignIn isFacebookEnabled />,
            },
            {
              path: "/broadcast/:broadcastId",
              element: <ProtectedRoute><Broadcast /></ProtectedRoute>,
            },
            {
              path: "/club/new",
              element: <ProtectedRoute><ClubForm /></ProtectedRoute>,
            },
            {
              path: "/club/:clubId",
              element: <Club />,
              loader: clubLoader,
            },
            {
              path: "/club/:clubId/edit",
              element: <ProtectedRoute><ClubForm /></ProtectedRoute>,
            },
            {
              path: "/club/:clubId/fixtures",
              element: <Fixtures />,
              // loader: fixtureLoader,
            },
            {
              path: "/club/:clubId/fixture",
              element: <ProtectedRoute><FixtureForm /></ProtectedRoute>,
              loader: clubLoader,
            },
            {
              path: "/club/:clubId/fixture/:fixtureId",
              element: <Match />,
            },
          ],
        },
        {
          path: "/scoreboard/:scoreboardId",
          element: <FixtureProvider><Scoreboard /></FixtureProvider>,
        },
        {
          path: "/scoreboard/:clubId/:fixtureId",
          element: <ScoreboardOnDemand />,
        },
        {
          path: "/forbidden",
          element: <Forbidden />,
        },
        {
          path: "/testgrid",
          element: <TestGrid />,
        },
        {
          path: "/testsound",
          element: <TestSound />,
        },
      ],
    },
  ]);

  return <RouterProvider router={router} />;
}

export default Routes;