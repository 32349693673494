import { useEffect, useState } from "react";
import { useAuth } from "../../hooks/useAuth";
import { db } from "../../firebaseConfig";
import { collection, getDocs, limit, orderBy, query, where } from "firebase/firestore";
import { Grid } from "@mui/joy";
import { dateTimeString } from "../../helpers/DateTime";
import Sheet from "../../common/Sheet";
import FixtureCardCompact from "../fixture/FixtureCardCompact";

const BroadcastList = () => {
  const { user } = useAuth();

  const [broadcasts, setBroadcasts] = useState([]);

  useEffect(() => {
    const fetchBroadcasts = async () => {
      let docQuery = query(
        collection(db, `broadcasts`),
        orderBy('updatedAt', 'desc'),
        orderBy('fixture'),
        limit(20)
      );
      if (!user.isAdmin) {
        docQuery = query(
          docQuery,
          where('auditing.isDeleted', '==', false)
        );
      }
      const docSnapshot = await getDocs(docQuery);
      const broadcastList = docSnapshot.docs.map(doc => ({
        ...doc.data(),
        id: doc.id,
        scheduledAt: dateTimeString(doc.data().scheduledAt),
      }));
      console.log({broadcastList});
      setBroadcasts(broadcastList);
    };

    fetchBroadcasts();
  }, [user.isAdmin]);

  if (!broadcasts || !broadcasts.length) return;

  return (
    <Sheet title="Get amongst the crowd">
      <Grid 
        container 
        columns={{ xs: 2, md: 4 }}
        spacing={{ xs: 2 }}
        sx={{ flexGrow: 1 }}
      >
        {broadcasts.map((broadcast, index) => (
          <Grid xs={2} key={index} sx={ broadcast?.auditing?.isDeleted ? { opacity: "0.5" } : {}}>
            <FixtureCardCompact
              fixture={broadcast.fixture}
              link={`/broadcast/${broadcast.id}`}
            />
          </Grid>
        ))}
      </Grid>

    </Sheet>
  );
}

export default BroadcastList;