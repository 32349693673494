import React from 'react';
import { Score } from '../common/Score';
import { Grid } from '@mui/joy';

export function Scores({ matchId, team, goals, behinds, onUpdate }) {
  const totalScore = (goals, behinds) => {
    return (goals * 6) + (behinds * 1);
  };

  return (
    <Grid container direction="row" alignItems="center" alignContent="center">
      <Grid container justifyContent="center" xs={4}>
        <Score value={goals} style={{ pt: '0.0rem' }} /></Grid>
      <Grid container justifyContent="center" xs={4}>
        <Score value={behinds} style={{ pt: '0.0rem' }} /></Grid>
      <Grid container justifyContent="center" xs={4}>
        <Score value={totalScore(goals, behinds)} style={{ fontSize: '1.3rem', lineHeight: '1.0rem', mt: '-0.1rem' }} />
      </Grid>
    </Grid>
  );
};