import React from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { Scoreboard } from './Scoreboard';
import { FixtureProvider } from './FixtureContext';

export const ScoreboardOnDemand = ({ fixture }) => {
  const { clubId: paramClubId, fixtureId: paramFixtureId } = useParams();

  const clubId = fixture?.clubId || paramClubId;
  const fixtureId = fixture?.id || paramFixtureId;

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const props = {
    clubId,
    fixtureId,
    dimmer: searchParams.get('dimmer') ?? '1',
    top: searchParams.get('top') ?? '0',
    left: searchParams.get('left') ?? '0',
    width: searchParams.get('width') ?? '1',
    scale: searchParams.get('scale') ?? '1',
  }

  return <FixtureProvider><Scoreboard isEmbedded props={props} /></FixtureProvider>;
};