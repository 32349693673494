import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import { useEffect, useState } from 'react';
import { doc, onSnapshot, serverTimestamp, updateDoc } from 'firebase/firestore';
import { db } from '../../firebaseConfig';

import CircularProgress from '../common/CircularProgress';
import VersionCheck from '../utils/VersionCheck';
import Sheet from '../../common/Sheet';
import { getSecondsRemaining } from '../../helpers/GetSecondsRemaining';
import { FixtureProvider } from '../../scoreboard/FixtureContext';
import { Scoreboard } from '../../scoreboard/Scoreboard';
import { CommentList } from './CommentLists';
import { Alert, Box, Button, FormControl, FormHelperText, FormLabel, Stack, Switch } from "@mui/joy";
import { Delete, Edit, RestoreFromTrash } from '@mui/icons-material';

export const Broadcast = () => {
  const { broadcastId } = useParams();

  const { user } = useAuth();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [broadcast, setBroadcast] = useState(null);
  const [fixture, setFixture] = useState(null);
  const [isCommentsVisible, setIsCommentsVisible] = useState(false);

  // subscribe to the broadcast
  useEffect(() => {
    if (!broadcastId) return;

    const docRef = doc(db, `broadcasts/${broadcastId}`);

    const unsubscribe = onSnapshot(docRef, (docSnap) => {
      if (docSnap.exists()) {
        console.log('broadcast: ', docSnap.data());
        const updatedBroadcast = {
          id: docSnap.id,
          ...docSnap.data(),
        };
        setBroadcast(updatedBroadcast);
        setFixture(updatedBroadcast.fixture);
        setIsLoading(false);
      } else {
        console.error("Broadcast not found: ", docRef);
      }
    });

    return () => unsubscribe();

  }, [broadcastId]);

  const [, setSecondsOn] = useState(null);

  useEffect(() => {
    if (!fixture) return;

    let timeout;
    
    const updateTimer = () => {
      const secondsRemaining = getSecondsRemaining(fixture);
      if (secondsRemaining !== null) {
        setSecondsOn(secondsRemaining);
      }
      if (fixture.timerIsRunning && secondsRemaining > 0) {
        // update the timer every 100ms
        timeout = setTimeout(updateTimer, 100);
      }
    }

    updateTimer();

    return () => {
      clearTimeout(timeout);
    };
  }, [fixture]);

  if (isLoading) {
    return <CircularProgress sx={{ height: "50vh" }} />;
  }

  if (!broadcast || !fixture) {
    return (
      <Alert variant="soft" color="danger">
        Failed to load this match
      </Alert>
    );
  }

  const handleDelete = () => {
    console.log('delete broadcast: ', broadcast);

    const docRef = doc(db, `broadcasts/${broadcastId}`);

    try {
      updateDoc(docRef, {
        auditing: {
          isDeleted: true,
          deletedAt: serverTimestamp(),
          deletedBy: user.uid,
        }
      });
      console.log('broadcast deleted: ', broadcastId);
    } catch (error) {
      console.error('Error deleting broadcast: ', error);
    };
  }

  const handleUndelete = () => {
    console.log('restoring broadcast: ', broadcast);

    const docRef = doc(db, `broadcasts/${broadcastId}`);

    try {
      updateDoc(docRef, {
        auditing: {
          isDeleted: false,
          restoredAt: serverTimestamp(),
          restoredBy: user.uid,
        }
      });
      console.log('broadcast restored: ', broadcastId);
    } catch (error) {
      console.error('Error restoring broadcast: ', error);
    };
  }

  const handleCommentsSwitch = (e) => {
    setIsCommentsVisible(e.target.checked);
  };

  const handleScoringClick = () => {
    navigate(`/club/${clubId}/fixture/${fixtureId}`);
  }

  const parts = broadcast.fixtureRef.path.split('/');
  const clubId = parts[1];
  const fixtureId = parts[3];
  const scene = 'scenes/match';
  const scoreboardProps = {
    dimmer: '1',
    top: '0',
    left: '0',
    width: '1',
    scale: '1',
  };
  const message = {}

  return (
    <>
      <VersionCheck />
      <Box display="flex" justifyContent="center" alignItems="center" sx={{ width: "100%", mt: '0.2rem' }}>
        <Sheet variant="outlined" sx={{ height: "200px", width: "300px", minWidth: "300px", overflow: 'hidden' }}>
          { scene === 'scenes/match' ? (
            <FixtureProvider>
              <Scoreboard
                initScene={scene}
                initContent={fixture}
                isEmbedded
                isForcedLive
                props={{ ...scoreboardProps, clubId, fixtureId: fixture.id }}
              />
            </FixtureProvider>
          ) : (
            <FixtureProvider>
              <Scoreboard
                initScene={scene}
                initContent={message}
                isEmbedded
                isForcedLive
                props={{ ...scoreboardProps, clubId }}
              />
            </FixtureProvider>
          )}
        </Sheet>
      </Box>

      { user?.isAdmin && (
        <Stack direction="row" spacing={1} justifyContent="center" sx={{ mt: '0.5rem' }}>
          <Button variant="soft" onClick={handleScoringClick}>
            <Edit fontSize="sm" sx={{ mr: "0.2rem" }} /> Scoring
          </Button>
          { !broadcast?.auditing?.isDeleted ? (
            <Button variant="soft" color="danger" onClick={handleDelete}>
              <Delete fontSize="sm" sx={{ mr: "0.2rem" }} /> Delete
            </Button>
          ) : (
            <Button variant="soft" color="neutral" onClick={handleUndelete}>
              <RestoreFromTrash fontSize="sm" sx={{ mr: "0.2rem" }} /> Restore
            </Button>
          )}
        </Stack>
      )}

      <Sheet title="Comments">
        <FormControl orientation="horizontal" sx={{ mb: "1rem" }}>
          <Stack direction="row" display="flex" alignItems="flex-start">
            <Switch
              name="isCommentsVisible"
              checked={isCommentsVisible}
              label="Enable commenting"
              variant={isCommentsVisible ? 'solid' : 'outlined'}
              onChange={handleCommentsSwitch}
              size="lg"
              sx={{ mr: '0.5rem' }}
              />
            <Stack direction="column">
              <FormLabel>Enable commenting</FormLabel>
              <FormHelperText>
                Comments are unmoderated, enable at your own risk.
              </FormHelperText>
            </Stack>
          </Stack>
        </FormControl>

        { isCommentsVisible && <CommentList broadcast={broadcast} />}

      </Sheet>
    </>
  );
};