import React from 'react';

import { Button } from '@mui/joy';
import { Done, SportsScoreRounded } from '@mui/icons-material';
import '../css/pulse.css';

const quarterLettersAndNumbers = (str) => {
  const matches = str.match(/([A-Za-z]+)(\d+)/);

  if (matches) {
    const letters = matches[1];
    const numbers = parseInt(matches[2]);

    return { letters, numbers };
  }

  return { letters: 'Q', numbers: 1 };
};

export const Quarter = ({ match, eventModal, mode }) => {

  const { quarter, quarterLength } = match;

  const onClick = () => {

    const { letters, numbers } = quarterLettersAndNumbers(quarter);

    if (mode === 'finish' || numbers === 4) {
      // match is (probably) finished
      eventModal({
        type: 'finish',
        label: 'End of match',
        quarter: `${letters}${numbers + 1}`,
        quarterLength: quarterLength,
        isMatchFinished: true,
        result: (match.homeScore === match.awayScore)
          ? 'draw'
          : (match.homeScore > match.awayScore)
            ? 'home win'
            : 'away win'
      });
    } else {
      eventModal({
        type: 'quarter',
        label: 'New quarter',
        quarter: `${letters}${numbers + 1}`,
        quarterLength: quarterLength,
        isMatchFinished: false,
        result: null,
      });      
    }

  }

  const buttonStyle = {
    minWidth: "3rem",
    p: "0 0.5rem"
  }

  if (mode === 'finish') return (
    <Button variant="plain" onClick={onClick} sx={buttonStyle}>
      <SportsScoreRounded />
      <Done fontSize="xs" sx={{ m: '0.5rem 0 0 -0.7rem' }} />
    </Button>
  )

  const className = mode === 'wait' ? 'pulsing-text' : '';

  return (
    <Button className={className} variant="plain" onClick={onClick} sx={buttonStyle}>{quarter}</Button>
  )
};
