import React, { useEffect, useState } from 'react';
import { doc, serverTimestamp, updateDoc } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import Button from '@mui/joy/Button';
import { ConnectedTv, Tv } from '@mui/icons-material';
import { ScoreboardModal } from './ScoreboardModal';

import '../css/pulse.css';

export const ScoreboardButton = ({ clubId = null, match = null, message = null, isPulsing = false }) => {
  const [scoreboard, setScoreboard] = useState(null);
  const [scene, setScene] = useState(null);
  const [openScoreboardModal, setOpenScoreboardModal] = useState(false);

  useEffect(() => {
    if (match) {
      setScene('scenes/match');
    } else if (message) {
      setScene('scenes/message');
    }
  }, [match, message]);

  const updateScoreboard = async (scoreboard) => {
    console.log('updating scoreboard: ', scoreboard);

    const { id, ...scoreboardVals } = scoreboard;

    try {
      const scoreboardRef = doc(db, 'scoreboards', id);
      await updateDoc(scoreboardRef, {
        ...scoreboardVals,
        updatedAt: serverTimestamp(),
      });
      console.log('scoreboard updated!', { scoreboardVals });
    } catch (error) {
      console.error('Error updating document: ', error);
    }

    setScoreboard(scoreboard);
  };

  const handleScoreboardModalClose = (e, reason) => {
    if (reason !== 'backdropClick') setOpenScoreboardModal(false);
  };

  const handleScoreboardClick = (event, action = 'create') => {
    // open the modal
    setOpenScoreboardModal(true);
  };

  const TvIcon = ({scoreboard}) => {
    if (scoreboard) {
      return  <ConnectedTv size="md" />;
    } else {
      return <Tv />;
    }
  };

  const buttonStyle = {
    p: '0 0.5rem',
    lineHeight: '1rem',
  }

  return (
    <>
      <ScoreboardModal
        isOpen={openScoreboardModal}
        handleClose={handleScoreboardModalClose}
        prevScoreboard={scoreboard}
        updateScoreboard={updateScoreboard}
        clubId={match ? match.clubId : clubId}
        scene={scene}
        match={match}
        message={message}
        />
      <Button variant="plain" size="md" color="primary" className={isPulsing ? 'pulsing-circle' : ''} sx={buttonStyle} onClick={handleScoreboardClick}>
        <TvIcon scoreboard={scoreboard} />
      </Button>
    </>
  );
};
