import { useEffect, useRef, useState } from 'react';
import { useAuth } from '../hooks/useAuth';
import { Quarter } from './Quarter';
import Timer from './Timer';
import { Button, Snackbar, Stack } from '@mui/joy';
import { PauseCircleOutline, PlayCircleOutline, VolumeOffOutlined, VolumeUp } from '@mui/icons-material';
import { isIOS } from 'react-device-detect';

import '../css/pulse.css';
import '../css/fade-out.css';

export const Timing = ({ secondsOn, match, eventModal }) => {
  const { user } = useAuth();
  const [quarterMode, setQuarterMode] = useState(null);
  const [timerStyle, setTimerStyle] = useState(null);
  const [isNoisy, setIsNoisy] = useState(false);
  const [hasSirenSounded, setHasSirenSounded] = useState(true);
  const [isAudioEnabled, setIsAudioEnabled] = useState(false);
  const [audioError, setAudioError] = useState(null);
  const audioRef = useRef(new Audio('/sounds/siren-afl.ogg'));

  useEffect(() => {
    setQuarterMode(match.isFinished ? 'finish' : secondsOn === 0 ? 'wait' : null);

    setTimerStyle({
      p: 0,
      color: (secondsOn <= 120) ? '#f36' : 'white', // red time
    });

    if (secondsOn > 0) {
      // reset siren
      setHasSirenSounded(false);
    } else if (isAudioEnabled && !hasSirenSounded) {
      // sound the siren
      audioRef.current.play().catch(error => console.error('Audio playback failed:', error));
      setHasSirenSounded(true);
    }
  }, [secondsOn, match.isFinished, isAudioEnabled, audioRef, hasSirenSounded]);

  const onPause = () => {
    eventModal({
      type: 'pause',
      label: 'Pause',
    });
  }

  const onResume = () => {
    eventModal({
      type: 'resume',
      label: 'Resume',
    });
  }

  const onTimerClick = () => {
    eventModal({
      type: 'timer',
      label: 'Time change',
    });
  }
  
  const enableAudio = () => {
    audioRef.current.muted = true;
    audioRef.current.play().then(() => {
      audioRef.current.muted = false;
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
      audioRef.current.play().catch((error) => {
        console.error('Audio playback failed:', error);
        setAudioError('Audio playback unavailable.');
      });
      setIsAudioEnabled(true);
    }).catch((error) => {
      console.error('Audio permission not granted:', error);
      setAudioError('Audio permission not granted.');
      setIsAudioEnabled(false);
    });
  };

  const onBellClick = () => {
    if (!isNoisy) enableAudio();
    setIsNoisy(!isNoisy);
  }

  const handleErrorClose = (event, reason) => {
    setAudioError(null);
  };

  return (
    <>
      <Snackbar
        color="danger"
        variant="soft"
        open={audioError !== null}
        autoHideDuration={3000}
        onClose={handleErrorClose}
        sx={{ textAlign: 'center' }}
      >
        ❌ {audioError}
      </Snackbar>
      <Stack direction="row" display="flex" justifyContent="flex-start">
        <Quarter mode={quarterMode} match={match} eventModal={eventModal} />
        {secondsOn > 0 ? (
          match.timerIsRunning ? (
            <Button variant="plain" onClick={onPause} sx={{ p: "0 0.5rem" }}>
              <PauseCircleOutline />
            </Button>
          ) : (
            <Button variant="plain" onClick={onResume} sx={{ p: "0 0.5rem" }}>
              <PlayCircleOutline className="pulsing-circle" />
            </Button>
            )
        ) : (
          <Button variant="plain" className="fade-out" sx={{ p: "0 0.5rem" }}>
            <PauseCircleOutline />
          </Button>
        )}
        <Button variant="plain" onClick={onTimerClick} sx={timerStyle}>
          <Timer secondsOn={secondsOn} isRunning={match.timerIsRunning} />
        </Button>
        { (!isIOS || user.isAdmin) && (
          <Button variant="plain" onClick={onBellClick} sx={{ p: "0 0.5rem" }}>
            { isNoisy ? <VolumeUp fontSize="md" /> : <VolumeOffOutlined fontSize="md" /> }
          </Button>
        )}
      </Stack>
    </>
  )
};