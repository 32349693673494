import React, { useEffect, useState } from "react";
import { collection, getDocs, limit, orderBy, query, where } from "firebase/firestore";
import { db } from '../firebaseConfig';
import { useAuth } from "../hooks/useAuth";

import Link from "../components/common/Link";
import { Box, Card, CardContent, CardCover, Grid, Typography } from "@mui/joy";
import { TeamEmblem } from "../common/TeamEmblem";
import { Result } from "./Result";
import Timer from "../common/Timer";
import { getSecondsRemaining } from "../helpers/GetSecondsRemaining";
import { dateTimeString, formatDateRelative } from "../helpers/DateTime";
import { formatGender } from "../helpers/Gender";
import { formatGrade } from "../helpers/Grade";

const FixturesList = ({ clubId }) => {
  const [fixtures, setFixtures] = useState([]);
  const { user } = useAuth();

  useEffect(() => {
    const fetchFixtures = async () => {
      let fixturesQuery = query(
        collection(db, `clubs/${clubId}/fixtures`),
        orderBy('updatedAt', 'desc'),
        orderBy('scheduledAt', 'desc'),
        limit(20)
      );
      if (!user.isAdmin) {
        fixturesQuery = query(
          fixturesQuery,
          where('auditing.isDeleted', '==', false)
        );
      }

      const fixtureSnapshot = await getDocs(fixturesQuery);
      const fixtureList = fixtureSnapshot.docs.map(doc => ({
        ...doc.data(),
        id: doc.id,
        fixtureId: doc.id,
        clubId: clubId,
        scheduledAt: dateTimeString(doc.data().scheduledAt),
      }));
      console.log({fixtureList});
      setFixtures(fixtureList);
    };

    fetchFixtures();
  }, [clubId, user.isAdmin]);

  const coverStyle = {
    position: 'absolute',
    height: '100%',
    width: '100%',
    zIndex: 0,
    opacity: '0.5',
  };

  const emblemBoxStyle = {
    position: 'absolute',    
    top: 0,
    opacity: '0.1',
    width: '100%',
    height: '100%',
  }
  
  const emblemImgStyle = {
    position: 'absolute',
    top: '-3rem',
    maxHeight: 'none',
    margin: 0,
    maxWidth: '50%',
  }

  const emblemHomeStyle = {
    ...emblemImgStyle,
    left: '-10%',
    transform: 'rotate(-15deg)',
  };

  const emblemAwayStyle = {
    ...emblemImgStyle,
    right: '-10%',
    transform: 'rotate(15deg)',
  };

  const coverBg = { 
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 0,
    width: '100%',
    height: '100%',
    // backgroundImage: 'radial-gradient(circle, #5c0067 0%, #00d4ff 100%)',
    backgroundImage: 'radial-gradient(circle, rgba(0,0,0,1) 25%, rgba(0,0,0,0) 100%)',
  };

  return (
    <Grid 
      container 
      columns={{ xs: 2, md: 4 }}
      spacing={{ xs: 2 }}
      sx={{ flexGrow: 1 }}
    >
      {fixtures.map((fixture, index) => (
        <Grid xs={2} key={index} sx={ fixture?.auditing?.isDeleted ? { opacity: "0.5" } : {}}>
          <Card variant="outlined" sx={{ overflow: 'hidden' }}>
            <CardCover sx={coverStyle}>
              <Box sx={emblemBoxStyle}>
                <TeamEmblem src={fixture?.homeClub?.imageUrl} team={fixture.homeTeam} style={emblemHomeStyle} />
              </Box>
              <Box sx={emblemBoxStyle}>
                <TeamEmblem src={fixture?.awayClub?.imageUrl} team={fixture.awayTeam} style={emblemAwayStyle} />
              </Box>
              <Box sx={coverBg} />
            </CardCover>
            <CardContent>
              <Grid container spacing={0} sx={{ textAlign: 'center' }}>
                <Grid container xs={12} sm={6} md={12} justifyContent={{ xs: 'center', md: 'space-around' }}>
                  <Link overlay to={`/club/${clubId}/fixture/${fixture.id}`} underline="none">
                    <Typography fontSize="xs">
                      {formatGender(fixture.gender)} <strong>{formatGrade(fixture.grade)}</strong>
                      {fixture.round && (<> &bull; Round {fixture.round}</>)}
                    </Typography>
                  </Link>
                </Grid>
                <Grid container xs={12} sm={6} md={12} justifyContent="center">
                  <Typography fontSize="xs">
                    {formatDateRelative(fixture.scheduledAt, { isTodayExplicit: true })}
                  </Typography>
                </Grid>
                { fixture.isStarted ? (
                  <>
                    { !fixture.isFinished && (
                      <Grid xs={12}>
                        <Typography fontSize="xs">{fixture.quarter}</Typography>
                        <Timer secondsOn={getSecondsRemaining(fixture)} />
                      </Grid>
                    )}
                    <Result fixture={fixture} />
                  </>
                ) : (
                  <>
                    <Grid xs={12} sm={5} md={12}><Typography>{fixture.homeTeam}</Typography></Grid>
                    <Grid xs={12} sm={2} md={12}><Typography fontSize="xs">vs</Typography></Grid>
                    <Grid xs={12} sm={5} md={12}><Typography>{fixture.awayTeam}</Typography></Grid>
                  </>
                )}
                <Grid container xs={12} justifyContent="center">
                  <Typography fontSize="xs">at {fixture.venue}</Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
}

export default FixturesList;