import { Grid } from "@mui/joy"

const cellStyle = {
  border: '0.1rem solid white',
};

// simple grid component to test layouts
export const TestGrid = () => {
  return (
    <>
      <Grid container spacing={8} sx={{ textAlign: 'center', fontSize: '2rem' }}>
        <Grid item xs={12} md={6} lg={4} xl={3} sx={{ backgroundColor: 'red', ...cellStyle }}>1</Grid>
        <Grid item xs={12} md={6} lg={4} xl={3} sx={{ backgroundColor: 'blue', ...cellStyle }}>2</Grid>
        <Grid item xs={6} md={6} lg={4} xl={3} sx={{ backgroundColor: 'green', ...cellStyle }}>3</Grid>
        <Grid item xs={4} md={4} lg={4} xl={3} sx={{ backgroundColor: 'purple', ...cellStyle }}>4</Grid>
        <Grid item xs={3} md={3} lg={3} xl={3} sx={{ backgroundColor: 'brown', ...cellStyle }}>5</Grid>
      </Grid>
    </>
  )
}