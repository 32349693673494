import { Typography } from "@mui/joy";
import Link from "../common/Link";
import Sheet from "../../common/Sheet";

export const Business = () => {

  return (
    <Sheet title="Business details">
      <Typography level="body-md">
        Business name: POINT POST
      </Typography>
      <Typography level="body-md">
        ABN: 86 802 927 761
      </Typography>
      <Typography level="body-md">
        Email <Link to="mailto:legal@pointpost.app">legal@pointpost.app</Link> for any enquiries.
      </Typography>
    </Sheet>
  );
}