import React, { useEffect, useState } from "react";
import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import { db } from '../firebaseConfig';

import TransitionGroup from "react-transition-group/TransitionGroup";
import Collapse from "@mui/material/Collapse";

import { Box, Grid, Stack, Typography } from "@mui/joy";
import { Adjust, CheckCircle, Circle, Delete, Done, NotStarted, PauseCircle, PlayCircle, RadioButtonChecked, WatchLater } from "@mui/icons-material";
import { TeamAvatar } from "../common/TeamAvatar";
import Timer from "../common/Timer";

export const EventTimeline = ({ match, eventModal, isEditing = false }) => {
  const [events, setEvents] = useState([]);
  const [eventsPending, setEventsPending] = useState([]);

  const clubId = match.clubId;
  const fixtureId = match.id;

  // subscribe to events
  useEffect(() => {
    const eventsQuery = query(
      collection(db, `clubs/${clubId}/fixtures/${fixtureId}/events`),
      orderBy('createdAt', 'desc')
    );

    const unsubscribe = onSnapshot(eventsQuery, (docSnap) => {
      const updatedData = docSnap.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setEvents(updatedData);
    });

    return () => unsubscribe();
  }, [clubId, fixtureId]);

  // also subscribe to pending events
  useEffect(() => {
    const eventsQuery = query(
      collection(db, `clubs/${clubId}/fixtures/${fixtureId}/eventsPending`),
      orderBy('createdAt', 'desc')
    );

    const unsubscribe = onSnapshot(eventsQuery, (docSnap) => {
      const updatedData = docSnap.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setEventsPending(updatedData);
    });

    return () => unsubscribe();
  }, [clubId, fixtureId]);

  if (!events) return;

  const handleDeleteEvent = (event, isPending = true) => {
    
    if (isPending) {
      // no confirmation required
      setEventsPending((prev) => [...prev.filter((i) => i !== event)]);
      eventModal(event, 'delete');
    } else {
      // TODO confirmation

      // remove the event from the timeline
      setEvents((prev) => [...prev.filter((i) => i !== event)]);

      // delete the event
      eventModal(event, 'delete');
    }

  };

  const handleApplyEvent = (event) => {
    console.log('applying event: ', event);
    eventModal(event, 'apply');

    // move the pending event to the timeline
    setEventsPending((prev) => [...prev.filter((i) => i !== event)]);
    setEvents((prev) => [event, ...prev]);
  }

  // earliest timeline event is styled differently
  const isEarliest = (index) => index === (events.length - 1);

  return (
    <>
      <TransitionGroup>
        {eventsPending.map((event, index) => (
          <Collapse key={index}>
            {renderEvent({ event, match, handleDeleteEvent, handleApplyEvent, isPending: true, isConnected: false })}
          </Collapse>
        ))}
      </TransitionGroup>
      <TransitionGroup>
        {events.map((event, index) => (
          <Collapse key={index}>
            {renderEvent({ event, match, handleDeleteEvent, isEditing, isConnected: !isEarliest(index) })}
          </Collapse>
        ))}
      </TransitionGroup>
    </>
  );
}

const renderEvent = ({ event, match, handleDeleteEvent, handleApplyEvent, isPending = false, isEditing = false, isConnected = true }) => {

  const eventStyle = {
    bgcolor: isPending ? 'success.solidBg' : 'transparent',
    p: '0.25rem 0 0.25rem',
    minHeight: '1.8rem',
    lineHeight: '1.4rem',
  };

  const actionStyle = {
    display: 'flex',
    height: '100%',
    minWidth: '2.5rem',
    cursor: 'pointer',
  }

  const timerSeconds = Math.max(0, event.secondsRemaining);

  return (
    <Box sx={ eventStyle }>
      <Grid container spacing={0} sx={{ flexGrow: 1 }} alignItems="center">
        <Grid xs={3} display="flex" justifyContent="flex-end">
          { event.type !== 'start' && event.type !== 'quarter' && event.type !== 'finish' && (
            <Timer secondsOn={timerSeconds} fontSize="xs" sx={{ pt: '0.1rem' }} />
          )}
        </Grid>
        <Grid xs={2} sm={1} display="flex" justifyContent="flex-end">
          { event.team ? (
              <TeamAvatar
                src={match?.[event.team + 'Club']?.imageUrl}
                team={match?.[event.team + 'Team']}
                sx={{ marginRight: '0', "--Avatar-size": "1.3rem" }} />
            ) : event.type !== 'finish' && (
              <Typography fontSize="xs" sx={{ pr: '0.2rem' }}>{event.quarter ?? "Q1"}</Typography>
            )}
        </Grid>
        <Grid container xs={2} display="flex" justifyContent="center">
          <EventIcon eventType={event.type} isConnected={isConnected} />
        </Grid>
        <Grid xs>
          <Typography fontSize="xs">{event.label}</Typography>
        </Grid>
        <Grid xs={1} sm={1} display="flex" alignItems="stretch" justifyContent="flex-end">
          <Stack direction="row" display="flex" alignItems="center">
            {isPending ? (
              <>
                <Box variant="plain" color="secondary" sx={actionStyle} size="xs" onClick={() => handleApplyEvent(event)}><Done fontSize="sm" /></Box>
                {/* <Box variant="plain" color="secondary" sx={actionStyle} size="xs"><Edit fontSize="sm" /></Box> */}
                <Box variant="plain" color="secondary" sx={actionStyle} size="xs" onClick={() => handleDeleteEvent(event)}><Delete fontSize="sm" /></Box>
              </>
            ) : isEditing && (
              <>
                {event.team && (
                  <Box variant="plain" color="secondary" sx={actionStyle} size="xs" onClick={() => handleDeleteEvent(event, false)}><Delete fontSize="sm" /></Box>
                )}
              </>
            )}
          </Stack>
        </Grid>
      </Grid>
    </Box>
  )
};

const EventIcon = ({ eventType, isConnected }) => {

  const iconSwitch = (icon) => {
    switch (icon) {
      case 'goal':
        return <RadioButtonChecked />;
      case 'behind':
        return <Adjust />;
      case 'timer':
        return <WatchLater />;
      case 'pause':
        return <PauseCircle />;
      case 'start':
      case 'quarter':
        return <NotStarted />;
      case 'resume':
        return <PlayCircle />;
      case 'finish':
        return <CheckCircle />;
      default:
        return <Circle />;
    }
  }

  // style the vertial bar connecting timeline icons
  const iconWrapStyle = {
    mt: '0.60rem',  // move everything up/down
    mb: '-1.40rem', // make the bar taller
    background: isConnected && 'linear-gradient(#fff, #fff) no-repeat center/0.2rem 100%;'
  };

  const iconStyle = {
    mt: '-1.35rem', // move the icon up/down
    ml: '-0.028rem', // move the icon left/right
  };

  return (
    <Box sx={iconWrapStyle}>
      <Box sx={iconStyle}>
        {iconSwitch(eventType)}
      </Box>
    </Box>
  );
}