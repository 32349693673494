import React from 'react';
import { createGlobalStyle } from "styled-components";
import { Outlet } from 'react-router-dom';
import { NavBar } from './NavBar';
import Box from '@mui/joy/Box';
import Footer from '../components/layout/Footer';

const WrapStyle = createGlobalStyle`
  html {
    font-size: 20px;
  }

  @media screen and (min-width: 1024px) {
    html {
      font-size: 2vw;
    }
  }

  body {
    background-color: env(safe-area-inset-top, #50723C); /* Set the background color for the status bar */
  }

  .MuiAppBar-root {
    background-color: env(safe-area-inset-top, #50723C); /* Set the background color for the AppBar */
  }
  
  .navbar {
    background-color: #50723C;
  }
`;

const footerHeight = "2rem";

export const Wrap = () => {
  return (
    <Box sx={{ pb: footerHeight }}>
      <WrapStyle />
      <NavBar />
      <Box sx={{ m: "0.5em 0.8em" }}>
        <Outlet />
      </Box>
      <Footer sx={{ height: footerHeight }} />
    </Box>
  );
}