import React from 'react';
import { AppBar } from '@mui/material';
import { Box, Typography } from '@mui/joy';
import Link from '../common/Link';
import { PolicyOutlined, WhatsApp } from '@mui/icons-material';

const Footer = ({ sx }) => {

  const whatsAppUrl = process.env?.REACT_APP_WHATSAPP_URL;

  return (
    <>
      <AppBar
        position="fixed"
        enableColorOnDark
        sx={{
          top: 'auto',
          bottom: 0,
          height: '2rem',
          ...sx
        }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            height: '100%',
            bgcolor: 'primary.main',
            textAlign: 'center',
            p: '0.5rem',
          }}
        >
          <>
            <Typography fontSize="xs">
              <Link to="/legal" sx={{ color: 'text.primary' }}><PolicyOutlined fontSize="sm" /> Legal</Link>
            </Typography>
            { whatsAppUrl && (
              <Typography fontSize="xs">
                <Link to={whatsAppUrl} sx={{ color: 'text.primary' }}>
                  Get help and info on <WhatsApp fontSize="sm" sx={{ ml: "0.2rem" }} /> WhatsApp
                </Link>
              </Typography>
            )}
          </>
        </Box>
      </AppBar>
    </>
  );
};

export default Footer;