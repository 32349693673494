import { Button, Typography } from "@mui/joy";
import Sheet from "../../common/Sheet";
import { TimerOutlined } from "@mui/icons-material";

const ClubC2A = ({ onClick }) => {

  return (
    <Sheet title="Looking to score?">
      <Typography level="body-sm">
        If you're responsible for record keeping and scoreboard control today, find your club and request access.
      </Typography>
      <Button variant="outlined" onClick={onClick}><TimerOutlined sx={{ mr: "0.2rem" }} /> Controller access</Button>
    </Sheet>
  );
}

export default ClubC2A;