import { Avatar, Chip } from "@mui/joy";

export const ClubChip = ({ club, isSelected, handleClick, clickParams }) => {

  return (
    <Chip
      color="primary"
      variant={isSelected ? "solid" : "outlined"}
      size="sm"
      onClick={() => handleClick(clickParams)}
      startDecorator={<Avatar src={club?.imageUrl} alt={club.name} />}
      sx={{
        maxWidth: "70%", mb: "0.2rem",
        "--Chip-paddingInline": "0.5rem",
        "--Chip-radius": "1rem",
        "--Chip-decoratorChildHeight": "1rem",
        "--Chip-minHeight": "1.4rem"
      }}
    >
      {club.name}
    </Chip>
  );

};