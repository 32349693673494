import { Button, Stack } from "@mui/joy";

export const TimerForm = ({handleTimerChange}) => {
  const addSeconds = (value) => {
    handleTimerChange(value);
  }

  return (
    <Stack direction="row" justifyContent="center">
      <Button variant="plain" onClick={() => addSeconds(-10)}>-10</Button>
      <Button variant="plain" onClick={() => addSeconds(-1)}>-1</Button>
      <Button variant="plain" onClick={() => addSeconds(1)}>+1</Button>
      <Button variant="plain" onClick={() => addSeconds(10)}>+10</Button>
    </Stack>
  );
}