import React from 'react';
import { Typography } from '@mui/joy';

const Timer = ({ secondsOn, fontSize = "lg", sx }) => {

  const formatTime = (secondsOn) => {
    const minutes = Math.floor(secondsOn / 60);
    const seconds = secondsOn % 60;

    return `${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  }

  return (
    <Typography level="monospace" fontSize={fontSize} sx={sx}>{formatTime(secondsOn)}</Typography>
  );
}

export default Timer;