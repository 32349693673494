import { Box } from "@mui/joy";
import { CircularProgress as CircularProgressJoy } from "@mui/joy";

const CircularProgress = ({ sx }) => {

  const style = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '90vh',
    ...sx
  };

  return (
    <Box sx={style}>
      <CircularProgressJoy variant="soft" />
    </Box>
  );
  
}

export default CircularProgress;