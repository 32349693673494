import { createContext, useContext, useEffect, useState } from "react";
import { auth, db } from "../firebaseConfig";
import { collection, doc, getDoc, runTransaction, serverTimestamp } from "firebase/firestore";
import { FacebookAuthProvider, getRedirectResult, onAuthStateChanged } from "firebase/auth";
import LogRocket from "logrocket";
import CircularProgress from "../components/common/CircularProgress";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState();
  const [wasAdmin, setWasAdmin] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (userAuth) => {

      console.log('userAuth: ', userAuth);

      // clear cached values
      localStorage.removeItem("club");

      if (!userAuth) {
        // sign out

        // clear user state so app displays sign-in options
        setUser(null);

      } else {
        // sign in

        // update and fetch user details from firestore
        const userRef = doc(db, "users", userAuth.uid);

        const userDoc = (await getDoc(userRef)).data();

        console.log('userDoc: ', userDoc);

        // fetch the user's ID token and check for admin claim
        const idTokenResult = await userAuth.getIdTokenResult();
        
        const updatedUserData = {
          ...(userDoc || {}),
          isAdmin: idTokenResult?.claims?.admin || false,
          email: userAuth?.email,
          displayName: userAuth?.displayName,
          emailVerified: userAuth?.emailVerified,
          phoneNumber: userAuth?.phoneNumber,
          photoURL: userAuth?.photoURL,
          photoUrl: userAuth?.photoURL, // note the casing difference
          providerData: userAuth?.providerData,
          sessionCount: userDoc?.sessionCount ? userDoc.sessionCount + 1 : 1,
        };

        // update user state and cache with auth data
        setUser({
          ...(userDoc || {}),
          ...userAuth,
          isAdmin: updatedUserData.isAdmin,
        });

        if (updatedUserData.isAdmin) {
          setWasAdmin(true);
          console.log("Hi, Boss!");
          console.log({ updatedUserData });
        }

        await runTransaction(db, async (transaction) => {
          try {
            // upsert user
            transaction.set(userRef, {
              ...updatedUserData,
              createdAt: userDoc?.createdAt ?? serverTimestamp(),
              lastSignInAt: serverTimestamp(),
              updatedAt: serverTimestamp(),
            }, { merge: true });
          } catch (error) {
            console.error("Error upserting user data:", error);
          }

          try {
            // add an auth document for the user
            transaction.set(doc(collection(db, `users/${userAuth.uid}/sessions`)), {
              ...updatedUserData,
              updatedAt: serverTimestamp(),
            });
          } catch (error) {
            console.error("Error recording user session:", error);
          }
        });
  
        if (process.env.NODE_ENV === 'production' && process.env.REACT_APP_LOGROCKET_ID) {
          LogRocket.identify(userAuth.uid, {
            name: userAuth?.displayName,
            email: userAuth?.email,
          });
        }
      }
  
      setLoading(false);

    });

    getRedirectResult(auth)
      .then((result) => {
        console.log({ result });
        
        if (result?.credential) {
          // const credential = FacebookAuthProvider.credentialFromResult(result);
          const providerId = result.credential.providerId;
          if (providerId === 'facebook.com') {
            console.log('Redirected from Facebook sign-in');
          } else if (providerId === 'google.com') {
            console.log('Redirected from Google sign-in');
          }
          console.log('Credential:', result.credential);

          const token = result.credential.accessToken;
          console.log({ token });  
        }
    
        console.log('%cAUTH SUCCESS <3', 'color: #0f0;');

        // IdP data available using getAdditionalUserInfo(result)
        // ...
      }).catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData?.email;
        // AuthCredential type that was used.
        const credential = FacebookAuthProvider.credentialFromError(error);
        
        console.log('%cAUTH ERROR :(', 'color: #f00;', { errorCode, errorMessage, email, credential });
        console.log({error});
      });

    return () => unsubscribe();
  }, []);

  if (loading) {
    <CircularProgress />;
  }

  return (
    <AuthContext.Provider value={{ user, wasAdmin, isAuthLoading: loading }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};