import { Accordion, AccordionDetails, AccordionGroup, AccordionSummary } from "@mui/joy";
import { ConnectedTv } from "@mui/icons-material";

export const HelpScorer = ({ onClick }) => {

  return (
    <AccordionGroup size="sm">
      <Accordion>
        <AccordionSummary>Scoreboard only shows "hdip" logo</AccordionSummary>
        <AccordionDetails>
          <p>Video signal is lost. Try this, in order:</p>
          <ol>
            <li>Power cycle the white box in the store room behind the bar and wait 30 seconds</li>
            <li>Power cycle the black box above the bar window and wait 30 seconds</li>
          </ol>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary>Scoreboard display seems stuck</AccordionSummary>
        <AccordionDetails>
          <p>Re-load the scoreboard to recover from an app glitch.</p>
          <ConnectedTv fontSize="sm" /> Use the scoreboard button to send the game to the scoreboard again and it should reload.
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary>Scoreboard showing a web browser</AccordionSummary>
        <AccordionDetails>
          <p>Video signal may have switched.</p>
          <ol>
            <li>Power cycle the small computer sitting in the bar window and wait 30 seconds</li>
          </ol>
        </AccordionDetails>
      </Accordion>
    </AccordionGroup>
  );
}
