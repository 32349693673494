import { Typography } from "@mui/joy";
import Sheet from "../../common/Sheet";
import Link from "../common/Link";

export const Privacy = () => {

  return (
    <Sheet title="Privacy Policy">
      <Typography level="body-md">
        Effective date: 16th July, 2024
      </Typography>
      <Typography level="body-sm">
        Welcome to <strong>pointpost</strong>. Your privacy is important to us, and we are
        committed to protecting your personal information. This Privacy Policy
        outlines how we collect, use, and protect your data when you use our app.
      </Typography>

      <Typography level="body-md">
        Information we collect
      </Typography>
      <Typography level="body-sm" component="ol">
        <li>Personal information: When you register and use pointpost, we may collect personal information such as your name, email address, and contact details.</li>
        <li>Usage data: We collect information on how you interact with the app, including your IP address, browser type, and access times.</li>
        <li>Cookies and tracking technologies: We use cookies and similar technologies to track activity on our app and store certain information.</li>
      </Typography>

      <Typography level="body-md">
        How we use your information
      </Typography>
      <Typography level="body-sm" component="ol">
        <li>To provide and improve our services: We use your information to operate, maintain, and enhance the features and functionality of pointpost.</li>
        <li>To communicate with you: We may use your contact information to send you updates, newsletters, and promotional materials.</li>
        <li>To ensure security: We use your data to protect our app and its users from security threats.</li>
      </Typography>

      <Typography level="body-md">
        Sharing your information
      </Typography>
      <Typography level="body-sm">
        We do not sell, trade, or otherwise transfer your personal information to outside parties, except as necessary to provide our services or as required by law.
      </Typography>

      <Typography level="body-md">
        Your rights
      </Typography>
      <Typography level="body-sm">
        You have the right to access, update, or delete your personal information at any time. If you wish to exercise these rights, please
        email <Link to="mailto:legal@pointpost.app">legal@pointpost.app</Link>.
      </Typography>

      <Typography level="body-md">Changes to this Privacy Policy</Typography>
      <Typography level="body-sm">
        We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated effective date.
      </Typography>

      <Typography level="body-md">Contact us</Typography>
      <Typography level="body-sm">
        If you have any questions or concerns about this Privacy Policy, please
        email <Link to="mailto:legal@pointpost.app">legal@pointpost.app</Link>.
      </Typography>
    </Sheet>
  );
}