import { Typography } from "@mui/joy";
import Sheet from "../../common/Sheet";
import Link from "../common/Link";

export const DataDeletion = () => {

  return (
    <Sheet title="Data Deletion">
      <Typography level="body-md">
        To delete your data, email your request to <Link to="mailto:delete@pointpost.app">delete@pointpost.app</Link>.
      </Typography>
    </Sheet>
  );
}