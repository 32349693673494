import { useEffect, useState } from "react";
import { collection, doc, getDoc, getDocs } from "firebase/firestore";
import { db } from "../firebaseConfig";
import { Close, Done, ExpandMore, LightMode, ModeNight } from "@mui/icons-material";
import { Modal, ModalDialog, ModalOverflow, Typography, Stack, Button, Sheet, Select, Option, FormControl, FormLabel, Box, Slider, Switch } from "@mui/joy";
import { ModalClose } from "../common/ModalClose";
import { Scoreboard } from "../scoreboard/Scoreboard";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { styled } from '@mui/joy';
import { FixtureProvider } from "../scoreboard/FixtureContext";

export const ScoreboardModal = ({ isOpen, handleClose, clubId, scene, match = null, message = null, prevScoreboard, updateScoreboard }) => {
  const scoreboardDefaults = {
    id: null,
    scale: 1,
    dimmer: 1,
    top: 0,
    left: 0,
    width: 1,
  };
  const [open, setOpen] = useState(false);
  const [scoreboards, setScoreboards] = useState([]);
  const [initProps, ] = useState(prevScoreboard ?? scoreboardDefaults);
  const [props, setProps] = useState(null);

  useEffect(() => {
    if (scene === 'scenes/match' && match) {
      setProps({
        ...initProps,
        sceneRef: doc(db, scene),
        clubId: match.clubId,
        fixtureId: match.id,
      });
    } else if (scene === 'scenes/message' && message) {
      setProps({
        ...initProps,
        sceneRef: doc(db, scene),
        clubId: message.clubId,
        messageId: message.id,
      });
    }
  }, [initProps, scene, match, message]);

  useEffect(() => {
    if (isOpen !== undefined) setOpen(isOpen);

    const fetchScoreboards = async () => {
      try {
        const clubScoreboardsSnapshot = await getDocs(collection(db, `clubs/${clubId}/scoreboards`));
    
        const scoreboardPromises = clubScoreboardsSnapshot.docs.map(async (scoreboardDoc) => {
          const scoreboardRef = doc(db, `scoreboards/${scoreboardDoc.id}`);
          const docSnap = await getDoc(scoreboardRef);
          return { ...docSnap.data(), id: docSnap.id };
        });
        
        const scoreboards = await Promise.all(scoreboardPromises);
        
        setScoreboards(scoreboards);
      } catch (error) {
        console.error('Error fetching scoreboards:', error);
      }
    };

    fetchScoreboards();

  }, [isOpen, clubId]);
  
  if (!isOpen) return;

  const handleScoreboardChange = (event, newValue) => {
    const selectedScoreboard = (newValue === '')
      ? scoreboardDefaults
      : scoreboards.find(scoreboard => scoreboard.id === newValue);
    
    const filteredScoreboardUpdates = Object.keys(selectedScoreboard)
      .filter(key => key in scoreboardDefaults)
      .reduce((acc, key) => {
        acc[key] = selectedScoreboard[key];
        return acc;
      }, {});

    setProps(props => ({
      ...props,
      ...filteredScoreboardUpdates
    }));
  };

  const handleFieldChange = (e) => {
    setProps({
      ...props,
      [e.target.name]: e.target.value,
    });
  };

  const handleDimmerSwitch = (e) => {
    const dimmer = e.target.checked ? 1 : 0.3;
    setProps({
      ...props,
      dimmer: dimmer,
    });
  };

  const handleApply = async () => {
    updateScoreboard(props);
    handleClose();
  };

  const handleCancel = () => {
    handleClose();
  }

  const controlStyle = { mt: '0.5rem' };

  const sliderLabelStyle = { mt: '-0.5rem', width: '100%', justifyContent: 'center' };

  const sliderStyle = { userSelect: 'none' };

  const StyledSwitch = styled(Switch)(({ theme }) => ({
    input: {
      position: 'relative',
    },
  }));

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}>
        <ModalOverflow>
          <ModalDialog
            aria-labelledby="Scoreboard"
            aria-describedby="Configure a scoreboard for the match"
            sx={{ textAlign: 'center' }}>
            <ModalClose />
            <Typography level="h4" component="h2" fontWeight="bold" textAlign="center">Scoreboard</Typography>
            { scoreboards ? (
              <>
                <Typography level="body2" component="p">
                  Show the live scores for this match on a connected screen.
                </Typography>
                <Select
                  id="scoreboard"
                  name="id"
                  value={props.id}
                  placeholder="Select a scoreboard"
                  required
                  sx={{ mt: '1rem' }}
                  onChange={handleScoreboardChange}>
                  { scoreboards.map((scoreboard, index) => (
                    <Option key={scoreboard.id} value={scoreboard.id}>{scoreboard.name}</Option>
                  ))}
                </Select>
                { props.id && (
                  <>
                    <Stack direction="row" width="100%" justifyContent="center" sx={{ m: '0.8rem 0' }}>
                      <FormLabel htmlFor="isDay" sx={{ mr: '0.5rem', fontSize: '0.8rem' }}><ModeNight sx={{ fontSize: '1rem', mr: '0.4rem' }} /></FormLabel>
                      <StyledSwitch
                        variant="solid"
                        name="isBright"
                        label="Day mode"
                        checked={props.dimmer >= 1}
                        onChange={handleDimmerSwitch}
                      />
                      <FormLabel htmlFor="isDay" sx={{ ml: '0.4rem', fontSize: '0.8rem' }}><LightMode sx={{ fontSize: '1.2rem', ml: '0.5rem' }} /></FormLabel>
                    </Stack>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography fontSize="sm">Display options</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <FormControl orientation="vertical" sx={controlStyle}>
                          <Slider
                            id="option-dimmer"
                            name="dimmer"
                            aria-label="Dimmer"
                            value={props.dimmer}
                            step={0.1}
                            marks
                            min={0.1}
                            max={1}
                            valueLabelDisplay="auto"
                            sx={sliderStyle}
                            onChange={(e) => handleFieldChange(e)}
                          />
                          <FormLabel htmlFor="dimmer" sx={sliderLabelStyle}>Brightness</FormLabel>
                        </FormControl>
                        <FormControl orientation="vertical" sx={controlStyle}>
                          <Slider
                            id="option-scale"
                            name="scale"
                            aria-label="Zoom"
                            value={props.scale}
                            step={0.1}
                            marks
                            min={0.1}
                            max={2}
                            valueLabelDisplay="auto"
                            sx={sliderStyle}
                            onChange={(e) => handleFieldChange(e)}
                          />
                          <FormLabel htmlFor="scale" sx={sliderLabelStyle}>Zoom</FormLabel>
                        </FormControl>
                        <FormControl orientation="vertical" sx={controlStyle}>
                          <Slider
                            id="option-width"
                            name="width"
                            aria-label="Width"
                            value={props.width}
                            step={0.1}
                            marks
                            min={0.1}
                            max={2}
                            valueLabelDisplay="auto"
                            sx={sliderStyle}
                            onChange={(e) => handleFieldChange(e)}
                          />
                          <FormLabel htmlFor="width" sx={sliderLabelStyle}>Width</FormLabel>
                        </FormControl>
                        <FormControl orientation="vertical" sx={controlStyle}>
                          <Slider
                            id="option-top"
                            name="top"
                            aria-label="Vertical position"
                            value={props.top}
                            step={1}
                            min={-100}
                            max={100}
                            valueLabelDisplay="auto"
                            sx={sliderStyle}
                            onChange={(e) => handleFieldChange(e)}
                          />
                          <FormLabel htmlFor="top" sx={sliderLabelStyle}>Vertical position</FormLabel>
                        </FormControl>
                        <FormControl orientation="vertical" sx={controlStyle}>
                          <Slider
                            id="option-left"
                            name="left"
                            aria-label="Horizontal position"
                            value={props.left}
                            step={1}
                            min={-100}
                            max={100}
                            valueLabelDisplay="auto"
                            sx={sliderStyle}
                            onChange={(e) => handleFieldChange(e)}
                          />
                          <FormLabel htmlFor="left" sx={sliderLabelStyle}>Horizontal position</FormLabel>
                        </FormControl>
                        <Typography level="body1" fontWeight="bold" mt="1rem" component="h3">Preview (4:3 screen)</Typography>
                        <Box display="flex" justifyContent="center" alignItems="center" sx={{ mt: '0.2rem' }}>
                          <Sheet variant="outlined" sx={{ height: '200px', width: 'calc(200px * 1.33)', minWidth: 'calc(200px * 1.33)', overflow: 'hidden' }}>
                            { scene === 'scenes/match' ? (
                              <FixtureProvider>
                                <Scoreboard initScene={scene} initContent={match} isEmbedded props={{ ...props, clubId, fixtureId: match.id }} />
                              </FixtureProvider>
                            ) : (
                              <FixtureProvider>
                                <Scoreboard initScene={scene} initContent={message} isEmbedded props={{ ...props, clubId }} />
                              </FixtureProvider>
                            )}
                          </Sheet>
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                    </>
                  )}
                </>
              ) : (
                <Typography level="p">No scoreboards currently available for your club.</Typography>
              )}
            <Stack direction="row" justifyContent="center" spacing={2} sx={{ mt: '1rem' }}>
              <Button variant="solid" size="md" onClick={handleApply}>
                <Done fontSize="sm" sx={{ mr: '0.2rem' }} />Apply
              </Button>
              <Button variant="plain" size="md" onClick={handleCancel}>
                <Close fontSize="sm" sx={{ mr: '0.2rem' }} />Cancel
              </Button>
            </Stack>
          </ModalDialog>
        </ModalOverflow>
      </Modal>
    </>
  );
}