import { useEffect, useState } from "react";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../firebaseConfig";
import { Close, Done } from "@mui/icons-material";
import { Modal, ModalDialog, ModalOverflow, Typography, Stack, Button, FormControl, FormLabel, Box, Textarea } from "@mui/joy";
import { ModalClose } from "../common/ModalClose";

export const MessageModal = ({ isOpen, clubId, message, handleClose }) => {
  const [open, setOpen] = useState(isOpen);
  const [messageText, setMessageText] = useState(null);

  useEffect(() => {
    if (isOpen !== undefined) setOpen(isOpen);
  }, [isOpen]);
  
  useEffect(() => {
    setMessageText(message.text);
  }, [message]);
  
  if (!open) return;
  
  const handleSave = async () => {
    try {
      await setDoc(doc(db, `clubs/${clubId}/messages`, message.id), { ...message, text: messageText });
    } catch (e) {
      console.error('Error saving message:', e);
    }
    handleClose();
  };

  const handleCancel = () => {
    handleClose();
  }

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}>
      <ModalOverflow>
        <ModalDialog
          aria-labelledby="Message"
          aria-describedby="Edit a message to display"
          sx={{ textAlign: 'center' }}>
          <ModalClose />
          <Typography level="h4" component="h2" fontWeight="bold" textAlign="center">Message</Typography>
          <Stack spacing={2}>
            <FormControl>
              <FormLabel htmlFor="message-text">Message</FormLabel>
              <Textarea id="message-text" value={messageText} onChange={e => setMessageText(e.target.value)} />
            </FormControl>
            <Box>
              <Button variant="contained" color="primary" onClick={handleSave}><Done /> Save</Button>
              <Button variant="outlined" color="secondary" onClick={handleCancel}><Close /> Cancel</Button>
            </Box>
          </Stack>
        </ModalDialog>
      </ModalOverflow>
    </Modal>
  );
}