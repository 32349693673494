import React, { useState } from "react";
import { Card, Avatar, Box, Stack, Typography, IconButton } from "@mui/joy";
import { FavoriteBorder, ReportOutlined } from "@mui/icons-material";
import { formatDateRelative } from "../../helpers/DateTime";

export const Comment = ({ comment, handleAction }) => {
  const [isLiked, setIsLiked] = useState(false);
  const [isReported, setIsReported] = useState(false);
  const [likeCount, setLikeCount] = useState(comment?.likeCount || 0);

  const handleCommentClick = (comment, action) => {
    // optimistically update UI
    if (action === "like") {
      setLikeCount(!isLiked ? likeCount + 1 : likeCount - 1);
      setIsLiked(!isLiked);
    } else if (action === "report") {
      setIsReported(!isReported);
    }

    handleAction(comment.id, action);
  }

  return (
    <Card
      variant="plain"
      sx={{ width: "100%", p: "0.5rem 0.7rem 0.5rem 0.7rem" }}
    >
      <Stack direction="row" spacing={1} sx={{ m: "0 0 0 0rem" }}>
        <Avatar
          variant="soft"
          size="xs"
          src={comment?.user?.photoURL}
          alt={comment?.user?.displayName}
        />
        <Box sx={{ width: "100%" }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            spacing={2}
          >
            <Typography level="body-sm" sx={{ p: "0.2rem 0 0 0.1rem" }}>{comment?.user?.displayName}</Typography>
            <Stack direction="row" spacing={1}>
              <IconButton
                variant={isLiked ? "solid" : "plain"}
                color="primary"
                size="sm"
                onClick={() => handleCommentClick(comment, 'like')}
              >
                <FavoriteBorder sx={{ mr: "0.2rem" }} /> {likeCount}
              </IconButton>
              <IconButton
                variant={isReported ? "solid" : "plain"}
                color="danger"
                size="sm"
                onClick={() => handleCommentClick(comment, 'report')}
              >
                <ReportOutlined />
              </IconButton>
            </Stack>
          </Stack>
        </Box>
      </Stack>
      <Stack direction="column" spacing={1} sx={{ }}>
        <Box>
          <Typography level="body-sm">{comment?.message}</Typography>
        </Box>
        <Box display="flex" justifyContent="right" sx={{ position: "relative", fontStyle: "italic" }}>
          <Typography level="body-xs">{formatDateRelative(comment?.createdAt)}</Typography>
        </Box>
      </Stack>
    </Card>
  );
};
