import React from 'react';
import { Sheet as SheetJoy } from '@mui/joy';
import Title from './Title';

const Sheet = ({ children, title, link, sx }) => {
  return (
    <SheetJoy
      variant="soft"
      sx={{
        width: '100%',
        mx: 'auto', // margin left & right
        my: "1.5rem", // margin top & bottom
        py: "1rem", // padding top & bottom
        px: "0.8rem", // padding left & right
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        borderRadius: 'sm',
        boxShadow: 'md',
        ...sx
      }}
    >
      {title && <Title link={link}>{title}</Title>}
      {children}
    </SheetJoy>
  );
};

export default Sheet;