import { Box, Card, CardContent, CardCover, Grid, Stack, Typography, useTheme } from "@mui/joy";
import { formatDateRelative, getTimeDiff } from "../../helpers/DateTime";
import { formatGender } from "../../helpers/Gender";
import { formatGrade } from "../../helpers/Grade";
import { getSecondsRemaining } from "../../helpers/GetSecondsRemaining";
import { TeamEmblem } from "../../common/TeamEmblem";
import { TeamAvatar } from "../../common/TeamAvatar";
import Timer from "../../common/Timer";
import Link from "../common/Link";
import { ChevronLeft, ChevronRight, EmojiEventsOutlined } from "@mui/icons-material";
import { useMediaQuery } from "@mui/material";

const FixtureCardCompact = ({ fixture, link }) => {

  const coverStyle = {
    position: 'absolute',
    height: '100%',
    width: '100%',
    zIndex: 0,
    opacity: '0.5',
  };

  const emblemBoxStyle = {
    position: 'absolute',    
    top: 0,
    opacity: '0.1',
    width: '100%',
    height: '100%',
  }
  
  const emblemImgStyle = {
    position: 'absolute',
    top: '-3rem',
    maxHeight: 'none',
    margin: 0,
    maxWidth: '50%',
  }

  const emblemHomeStyle = {
    ...emblemImgStyle,
    left: '-10%',
    transform: 'rotate(-15deg)',
  };

  const emblemAwayStyle = {
    ...emblemImgStyle,
    right: '-10%',
    transform: 'rotate(15deg)',
  };

  const coverBg = { 
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 0,
    width: '100%',
    height: '100%',
    // backgroundImage: 'radial-gradient(circle, #5c0067 0%, #00d4ff 100%)',
    backgroundImage: 'radial-gradient(circle, rgba(0,0,0,1) 25%, rgba(0,0,0,0) 100%)',
  };

  const formatted = formatDateRelative(fixture.scheduledAt, {
    at: '|', isTodayExplicit: false, dateFormat: 'EEE dd MMM', timeSeparator: '|'
  });
  const scheduled = [formatted.split('|')[0], formatted.split('|')[1]];

  const getResultString = (fixture) => {
    if (!fixture.result) return '';

    const pointDifference = Math.abs(fixture.homeScore - fixture.awayScore);
    
    if (fixture.result.startsWith('home win')) {
      return `${fixture.homeClub.initials} won by ${pointDifference} point${pointDifference !== 1 ? 's' : ''}`;
    } else if (fixture.result.startsWith('away win')) {
      return `${fixture.awayClub.initials} won by ${pointDifference} point${pointDifference !== 1 ? 's' : ''}`;
    } else if (fixture.result.startsWith('draw')) {
      return 'Match drawn';
    }
    
    return '';
  };

  const isLive = (fixture) => {
    if (!fixture.timerIntervalAt) return false;

    // games idle for over an hour are no longer live
    const { hours } = getTimeDiff(fixture.timerIntervalAt);
    return hours === 0 && fixture.isStarted && !fixture.isFinished;
  }

  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));

  return (
    <Card variant="outlined" sx={{
        overflow: 'hidden',
        fontSize: 'sm',
        '& .MuiTypography-root': {
          fontSize: '0.7rem',
          lineHeight: '0.8rem',
        },
        '& .MuiTypography-root.big': {
          fontSize: '0.9rem',
        },
        '& .MuiTypography-root.small': {
          fontSize: '0.6rem',
        },
      }}>
      <CardCover sx={coverStyle}>
        <Box sx={emblemBoxStyle}>
          <TeamEmblem src={fixture?.homeClub?.imageUrl} team={fixture.homeTeam} style={emblemHomeStyle} />
        </Box>
        <Box sx={emblemBoxStyle}>
          <TeamEmblem src={fixture?.awayClub?.imageUrl} team={fixture.awayTeam} style={emblemAwayStyle} />
        </Box>
        <Box sx={coverBg} />
      </CardCover>
      <CardContent>
        <Grid container spacing={0} sx={{ textAlign: 'center' }}>

          <Grid container xs={12} justifyContent={{ xs: 'center', md: 'space-around' }} sx={{ mb: '0.2rem' }}>
            <Link overlay to={link} underline="none">
              <Typography fontSize="xs">
                {formatGender(fixture.gender)} <strong>{formatGrade(fixture.grade)}</strong>
                {fixture.round && (<> &bull; Round {fixture.round}</>)}
              </Typography>
            </Link>
          </Grid>

          { fixture.isFinished && (
            <Grid container xs={12} justifyContent="center" sx={{ mb: '-0.8rem' }}>
              <Typography fontSize="xs" fontWeight="bold" display={{ xs: "block", sm: "none" }}>
                {getResultString(fixture)}
              </Typography>
            </Grid>
          )}

          <Grid container columns={24} justifyContent="center" sx={{ flexGrow: 1, m: '0.3rem 0' }}>
            <Grid xs={3} sm={3} display="flex" alignItems="center" justifyContent="center">
              <TeamAvatar src={fixture.homeClub?.imageUrl} team={fixture.homeTeam} size={isXs ? "sm" : "lg"} />
            </Grid>

            <Grid xs={6} sm={7} display="flex" alignItems="center" justifyContent="center">
              <Stack direction="column" alignItems="center">
                <Box display={{ xs: "none", sm: "block", mx: '0.5rem' }}>
                  <Typography fontSize="xs" lineHeight="0.9rem" px={'0.2rem'}>{fixture.homeTeam}</Typography>
                </Box>

                { fixture.isStarted && (
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <Typography fontWeight="bold" display={{ xs: "none", sm: "block" }}>{fixture.homeGoals}</Typography>
                    <Typography fontWeight="bold" display={{ xs: "none", sm: "block" }}>{fixture.homeBehinds}</Typography>
                    <Typography fontWeight="bold" className="big">{fixture.homeScore}</Typography>
                  </Stack>
                )}
              </Stack>
            </Grid>

            <Grid xs={6} sm={4} display="flex" alignItems="center" justifyContent="center">
              { fixture.isStarted ? (
                <>
                  { fixture.isFinished ? (
                    <Stack direction="column" alignItems="center" sx={{ mt: '0.2rem' }}>
                      <Typography className="big" sx={{ position: 'relative' }}>
                        {fixture.result.startsWith('home win') && (
                          <ChevronLeft fontSize="sm" sx={{ position: 'absolute', left: '-1.0rem' }} />
                        )}
                        {fixture.result.startsWith('away win') && (
                          <ChevronRight fontSize="sm" sx={{ position: 'absolute', right: '-1.0rem' }} />
                        )}
                        <EmojiEventsOutlined fontSize="sm" />
                      </Typography>
                    </Stack>
                  ) : (
                    <Stack direction="column" alignItems="center">
                      <Typography fontSize="xs">{fixture.quarter}</Typography>
                      <Timer secondsOn={getSecondsRemaining(fixture)} fontSize="sm" />
                      {isLive(fixture) && (
                        <Typography
                          fontSize="xs"
                          sx={{
                            backgroundColor: '#00ee00',
                            color: '#000000',
                            fontWeight: 'bold',
                            padding: '0 0.3rem',
                            lineHeight: '0.8rem',
                            borderRadius: '0.2rem',
                            marginBottom: '0.1rem'
                          }}
                        >
                          LIVE
                        </Typography>
                      )}
                    </Stack>
                  )}
                </>
              ) : (
                <Stack direction="column" alignItems="center">
                  <Typography fontSize="xs" whiteSpace="nowrap">{scheduled[1]}</Typography>
                  <Typography fontSize="xs" whiteSpace="nowrap">{scheduled[0]}</Typography>
                </Stack>
              )}
            </Grid>

            <Grid xs={6} sm={7} display="flex" alignItems="center" justifyContent="center">
              <Stack direction="column" alignItems="center">
                <Box display={{ xs: "none", sm: "block" }}>
                  <Typography fontSize="xs" lineHeight="0.9rem" px={'0.2rem'}>{fixture.awayTeam}</Typography>
                </Box>

                { fixture.isStarted && (
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <Typography fontWeight="bold" display={{ xs: "none", sm: "block" }}>{fixture.awayGoals}</Typography>
                    <Typography fontWeight="bold" display={{ xs: "none", sm: "block" }}>{fixture.awayBehinds}</Typography>
                  <Typography fontWeight="bold" className="big">{fixture.awayScore}</Typography>
                </Stack>
              )}
              </Stack>
            </Grid>
            <Grid xs={3} sm={3} display="flex" alignItems="center" justifyContent="center">
              <TeamAvatar src={fixture.awayClub?.imageUrl} team={fixture.awayTeam} size={isXs ? "sm" : "lg"} />
            </Grid>
          </Grid>

          { fixture.isFinished && (
            <Grid container xs={12} justifyContent="center" sx={{ mt: '-0.8rem' }}>
              <Typography fontSize="xs" fontWeight="bold" display={{ xs: "none", sm: "block" }}>
                {getResultString(fixture)}
              </Typography>
            </Grid>
          )}

          <Grid container xs={12} justifyContent="center">
            <Typography fontSize="xs">{fixture.venue}</Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default FixtureCardCompact;