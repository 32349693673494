import { Typography } from '@mui/joy';
import React, { useState, useEffect } from 'react';

const CurrentTime = ({ sx = null }) => {
  const style = {
    color: '#ccf',
    ...sx,
  };

  const [time, setTime] = useState(new Date());

  useEffect(() => {
    const timer = setInterval(() => {
      setTime(new Date());
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const formatTime = (date) => {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'pm' : 'am';

    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+ minutes : minutes;
    
    return (
      <>
        {hours}:{minutes}<span style={{ fontSize: '0.7rem' }}>{ampm}</span>
      </>
    );
  }

  return (
    <Typography sx={style}>{formatTime(time)}</Typography>
  );
}

export default CurrentTime;